import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './FxMultiSelect.styles.scss';
import * as _ from 'lodash';

export const FxMultiSelect = (props: any) => {
    if (props.visibility === false) {
        return <></>;
    }
    const { name, onValueChange, validations, variant, size = "small", outputAs = "csv" } = props;

    const onChange = (event: any, value: any, reason: string) => {
        let selctedValueAry: any = [];

        value.forEach((item: any) => {
            selctedValueAry.push(item[props.valueField])
        });

        var selectedValue = outputAs == 'csv' ? selctedValueAry.join() : selctedValueAry;
        const data = { name, value: selectedValue };
        if (onValueChange) onValueChange(data);
    };

    const getSelectedItem = () => {
        let inputData = [];
        if (props.selectedValue && _.isString(props.selectedValue)) {
            if (props.selectedValue.indexOf(",") > -1) {
                inputData = props.selectedValue.split(",");
            } else {
                inputData.push(props.selectedValue);
            }
        }
        if (props.selectedValue && _.isArray(props.selectedValue)) {
            inputData = props.selectedValue;
        }

        let selectedOption: any = [];
        inputData.forEach((item: any) => {
            const option = props.options.find((opt: any) => {
                if (opt[props.valueField] == item) return opt;
            });
            selectedOption.push(option);
        });
        return selectedOption || [];
    };

    let className = props.className || "";

    if(!props.options) {
        return <></>;
    }
    return (
        <Autocomplete
            className={className}
            multiple
            options={props.options}
            getOptionLabel={(option: any) => option[props.displayField]}
            value={getSelectedItem()}
            disableClearable
            disabled={props.disabled}
            onChange={onChange}
            size={size}
            style={props.style}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.hideCaption === true ? null : props.label}
                    hiddenLabel={props.hideCaption}
                    required={props.required}
                    placeholder={props.placeHolderText}
                    variant={variant == 'outlined' ? 'outlined' : 'standard'}
                />
            )}
        />
    );
}