import React, { useCallback,useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Button from '@material-ui/core/Button';
import UploadIcon from '@material-ui/icons/CloudUpload';

export const FxUploader = (props: any) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ maxFiles: 1 })
  const [uploadClick, setUploadClick] = useState(false);

  const filesDisplay: any = acceptedFiles.map(file => (
    <div>{file.name}</div>
  ));

  const onUpload = () => {
    setUploadClick(true);
    props.onUpload(acceptedFiles);
  }

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          // isDragActive ? <p>Drop the file here ...</p> :
          // <p>Drag 'n' drop file here, or click to select file</p>
          <div className="row col-12 mt-2">
            <Button variant="contained" size="small" color="secondary">
              Choose File
                  </Button>
            {filesDisplay &&
              <span className="ml-1"> {filesDisplay}</span>
            }
            {acceptedFiles && acceptedFiles.length == 0 &&
              <span className="ml-1">No file chosen</span>
            }

          </div>
        }
      </div>
      {/* <div>
        {filesDisplay}
      </div> */}
      <div className="text-center m-4">
        <Button variant="contained" size="small" color="primary" onClick={onUpload} disabled={uploadClick}>
          Upload <span className="ml-1"><UploadIcon /></span>
        </Button>
      </div>
    </>
  )
}