import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import Popper from '@material-ui/core/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@material-ui/core/Fade';
import './FxPopper.styles.scss';

export const FxPopper = (props: any) => {
  return (
    
    <PopupState variant="popper" popupId={props.popperId}>
      {(popupState: any) => (
        <>
          { props.iconKey && <span className="pointer" title={props.action} {...bindToggle(popupState)}><i className={props.iconKey}></i></span>}
          { !props.iconKey &&
            <a className="stmt-add-if mr-2" title={props.action} {...bindToggle(popupState)}>
              {props.action}
            </a>
          }
          <Popper className="fxpopper-root" {...bindPopper(popupState)} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className="paper-props" {...bindToggle(popupState)}>
                  {props.children}
                </Paper>
              </Fade>
            )}
          </Popper>
        </>
      )}
    </PopupState>

    
  );
};