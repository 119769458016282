import React, { useState, useEffect } from 'react';
import './PreviewGroup.scss';
import { FxInput, AlertService, http, FxCurrency } from '../../fx-core';
import { Button, Card } from '@material-ui/core';
import { FormValidator } from '../../fx-core/helpers/formValidator';
import Checkbox from "@material-ui/core/Checkbox";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Carousel from "react-multi-carousel";
import dummyImage from "./../../../assets/images/dummy.png";
import check from "./../../../assets/images/check.png";

const PreviewGroup = (props) => {

    const [groupItemText, setGroupItemText] = useState(null);

    const optionsMap = {
        1: [{ id: 1, description: '8"' }, { id: 1, description: '12"' }],
        2: [{ id: 1, description: 'Thin', price: 10 }, { id: 1, description: 'Medium', price: 15 }],
        3: [{ id: 1, description: 'Mushroom' }, { id: 1, description: 'Tomato' }]
    };

    let options = props.options || [];
    if (options.length == 0) {
        options = optionsMap[props.groupTemplateId];
    }

    const getReferenceValuById = () => {
        let inputData = {
            referenceGroupCode: "GroupTemplate",
            referenceValueCodeId: props.groupTemplateId
        }
        let apiOptions: any = {
            url: `referenceValue/code/value`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            let result = res.data;
            setGroupItemText(result);
        });
    };

    useEffect(() => {
        getReferenceValuById();
    }, []);

    var cardStyle = {
        width: '450px'
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div className="previewgroup-ui">
            <div className="row pt-2 mt-2 pl-4">
                {props.groupTemplateId == 1 &&
                    <div className="">
                        <div className="custom-head pl-2">
                            <Card style={cardStyle}>
                                <RadioGroup row aria-label="position" name="position" defaultValue="eight">
                                    {
                                        options && options.length > 0 && options.map((item, i) => (
                                            <div key={i} className="pl-3">
                                                <FormControlLabel
                                                    name="firstSelection"
                                                    value={item.id}
                                                    control={<Radio color="primary" size="small" />}
                                                    label={item.description}
                                                />
                                            </div>
                                        ))
                                    }
                                    <span className="font-style-tmp1">{groupItemText}</span>
                                </RadioGroup>
                            </Card>
                        </div>
                    </div>
                }

                {props.groupTemplateId == 2 &&
                    <div className="">
                        <div className="custom-head pt-1">
                            <Card style={cardStyle}>
                                <div className="pl-4 pb-3">
                                    <Carousel swipeable={true} draggable={true} responsive={responsive}>
                                        {options && options.length > 0 && options.map((item, i) => (
                                            <div key={i}>

                                                <div>
                                                    {i == 0 &&
                                                        <div className="pt-3">
                                                            <div className="text-right pr-4 select-check">
                                                                <img src={check} alt="Check Mark" width="22" />
                                                            </div>
                                                            <div className="crust-ui-select col-12 py-2">
                                                                <div>{item.description}</div>
                                                                <div className="crust-price-select"> <FxCurrency value={item.price} /></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {i != 0 &&
                                                        <div className="crust-ui col-12 py-2">
                                                            <div>{item.description}</div>
                                                            <div className="crust-price"> <FxCurrency value={item.price} /></div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        ))
                                        }
                                         <span className="font-style-tmp2">{groupItemText}</span>
                                    </Carousel>
                                </div>
                            </Card>
                        </div>
                    </div>
                }

                {props.groupTemplateId == 3 &&
                    <div className="">
                        <Card style={cardStyle}>
                            <div className="pl-2 pt-2 pb-3">
                                <Carousel swipeable={true} draggable={true} responsive={responsive}>
                                    {options && options.length > 0 && options.map((row, i) => (
                                        <div>
                                            {i == 0 &&
                                                <div className="text-center">
                                                    <div className="font-15">{row.description}</div>
                                                    <div className="text-right pr-1 select-topping-check">
                                                        <img src={check} alt="Check Mark" width="22" /></div>
                                                    <div className="pt-1">
                                                        {!row.imageUrl && <img width="108" height="92" src={dummyImage} className="toppings-ui select-topping"></img>}
                                                        {row.imageUrl && <img width="108" height="92" src={row.imageUrl} className="toppings-ui select-topping"></img>}
                                                    </div>
                                                    <div className="pt-1">
                                                        <Button variant="contained" size="small"><b>REMOVE</b></Button>
                                                    </div>
                                                </div>
                                            }
                                            {i != 0 &&
                                                <div className="text-center">
                                                    <div className="font-15">{row.description}</div>
                                                    <div className="pt-1"> <img src={dummyImage} alt="Fresh Tomato" width="108" height="92" className="toppings-ui" /></div>
                                                    <div className="pt-1">
                                                        <Button variant="contained" color="primary" size="small"><b>ADD</b></Button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    ))
                                    }
                                     <span className="font-style-tmp3">{groupItemText}</span>
                                </Carousel>
                            </div>
                        </Card>
                    </div>
                }
            </div>
        </div>
    )
};

export default PreviewGroup;