import React, { useState, useEffect } from 'react';
import './ProductForm.scss';
import { FxInput, FxTextarea, AlertService, http, FxSelect, formatService, general, FxMultiSelect } from '../../fx-core';
import { Button, Link, Dialog, Card } from '@material-ui/core';
import { FormValidator } from '../../fx-core/helpers/formValidator';
import dummyImage from "./../../../assets/images/dummy.png";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import Checkbox from "@material-ui/core/Checkbox";
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const ProductForm = (props) => {
    const idKey = general.getIdKey();
    const defaultItem: any = {
        name: "",
        code: "",
        unit: "",
        unitQty: 0,
        id: props && props.productId.length > 0 ? props.productId : "",
        price: 0,
        taxPercent: 0,
        taxType: "",
        description: "",
        categories: "",
        isActive: true,
        processingTime: 0,
        storeId: props.storeId,
        productTags: "",
        tagArr: useState([]),
        timingIds: ""
    };
    let defaultLookUp: any = {
        ParentCategories: useState([]),
        Categories: useState([]),
        ProductUnit: useState([]),
        TaxType: useState([]),
        StoreTags: useState([]),
        ProductType: useState([]),
        Timings: useState([])
    }
    const [lookUp, setLookUp] = useState(defaultLookUp);
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});

    const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
    const [uploadConfig, setUploadConfig] = useState({});
    const [imageConfig, setImageConfig] = useState({});
    const [imageSlideOpen, setImageSlideOpen] = useState(false);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        item.validations = getInputValidations(item.name);
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleLookUp = (data) => {
        setLookUp(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleCheckbox = (event) => {
        handleItemChange({ [event.target.name]: event.target.checked });
    }

    const handleRadioChange = (event) => {
        handleItemChange({ [event.target.name]: event.target.value });
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                ProductUnit: { default: false },
                Categories: { default: false },
                ParentCategories: { default: false },
                TaxType: { default: false },
                StoreTags: { default: false },
                Timings: { default: false }
            },
            filters: { storeId: item.storeId }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            initLookupCallBack(response.data);
        });
    }

    const initLookupCallBack = (response) => {
        handleLookUp({ "ParentCategories": response.ParentCategories });
        handleLookUp({ "Categories": response.Categories });
        handleLookUp({ "ProductUnit": response.ProductUnit });
        handleLookUp({ "TaxType": response.TaxType });
        handleLookUp({ "StoreTags": response.StoreTags });
        computeCategoryNames();

        handleItemChange({ "taxType": response.TaxType[0].code });
        getTimingList();
        if (item.id > 0) {
            getProductById();
        }
    }

    const getCategories = () => {        
        let filters: any = {
            storeId: general.getOidObjFromVal(item.storeId)
        };
        let inputData = {
            filters,
            sorters: {}
        };
        let apiOptions: any = {
            url: `categories/find`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let categories = response.data.items;
            let categoryArr = [];
            categoryArr.push({
                "id": -1,
                "text": "Please select...",
                "code": "-1",
                "isDefault": false,
                "attributes": null
            });
            for (let item of categories) {
                const categoryId = general.getIdFromObj(item);
                categoryArr.push({
                    "id": categoryId,
                    "text": item.categoryName,
                    "code": item.categoryCode,
                });
            }
            handleLookUp({ "Categories": categoryArr });
            const ProductUnit = 
            [
                {
                    "id": 1,
                    "text": "liter",
                    "code": "liter",
                    "isDefault": false,
                    "attributes": null
                },
                {
                    "id": 2,
                    "text": "pc",
                    "code": "pc",
                    "isDefault": false,
                    "attributes": null
                },
                {
                    "id": 3,
                    "text": "cup",
                    "code": "cup",
                    "isDefault": false,
                    "attributes": null
                }
            ];
            const TaxType = 
            [
                {
                    "id": 1,
                    "text": "Inclusive",
                    "code": "Inclusive",
                    "isDefault": false,
                    "attributes": null
                },
                {
                    "id": 2,
                    "text": "Exclusive",
                    "code": "Exclusive",
                    "isDefault": false,
                    "attributes": null
                }
            ];            
            const StoreTags = 
            [
                {
                    "id": 1000,
                    "text": "Gluten Free",
                    "code": null,
                    "isDefault": false,
                    "attributes": null
                },
                {
                    "id": 1001,
                    "text": "Halal",
                    "code": null,
                    "isDefault": false,
                    "attributes": null
                },
                {
                    "id": 1002,
                    "text": "Nuts Free",
                    "code": null,
                    "isDefault": false,
                    "attributes": null
                },
                {
                    "id": 1003,
                    "text": "Vegetarian",
                    "code": null,
                    "isDefault": false,
                    "attributes": null
                },
                {
                    "id": 1004,
                    "text": "Vegan",
                    "code": null,
                    "isDefault": false,
                    "attributes": null
                }
            ];
            const Timings = [];
            handleLookUp({ "ProductUnit": ProductUnit });
            handleLookUp({ "TaxType": TaxType });
            handleLookUp({ "StoreTags": StoreTags });
            handleLookUp({ "Timings": Timings });
            handleItemChange({ "taxType": TaxType[0].code });
            if (item.id.length > 0) {
                getProductById();
            }            
        })
    }

    const getTimingList = () => {
        let inputData = { storeId: item.storeId }

        let apiOptions: any = {
            url: 'timing/query',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            for (let item of result) {
                let startTime = formatService.getTimeDisplay(item.startTime);
                let endTime = formatService.getTimeDisplay(item.endTime);
                item.displayText = item.name+" (" +startTime +" - " + endTime+")" ;
            }
            handleLookUp({ "Timings": result });
        })
    }

    const computeCategoryNames = () => {
        let parentCatMap = {};
        for (let parentCat of lookUp.ParentCategories) {
            parentCatMap[parentCat.id] = parentCat;
        }
        for (var category of lookUp.Categories) {
            if (category.attributes && parentCatMap[category.attributes.parentCategoryId]) {
                let parentCategory = parentCatMap[category.attributes.parentCategoryId].text;
                category.text = parentCategory + "/" + category.text;
            }
        }
    }


    //Validation area starts
    const validationMap_Item = {
        name: ["required"],
        code: ["required"]
    };

    const getValidations = () => {
        let validationMap;
        validationMap = validationMap_Item;
        return validationMap;
    }

    const getInputValidations = (name) => {
        let validationMap = getValidations();
        return validationMap[name];
    }

    const bulkValidate = () => {
        let items: any = [];
        let validationMap = getValidations();
        for (var key in validationMap) {
            let result = { name: key, value: item[key], validations: validationMap[key] }
            items.push(result);
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };
    //Validation area ends

    const getProductById = () => {
        let filters: any = {};
        filters[idKey] = general.getOidObjFromVal(item.id);

        let inputData: any = {
            filters,
            sorters: {}
        }
        let apiOptions: any = {
            url: `products/find`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            let results = res.data.items;
            if(results.length > 0) {
                let result = results[0];
                result.imageUrl = general.getImageUrl(result.imagePath);
                result.id = general.getIdFromObj(result);
                result.storeId = general.getOidValFromKey(result.storeId);
                setItem(result);
            }
        });
    };

    const addProduct = () => {
        const isValid = bulkValidate();
        if (isValid) {
            /*let inputData = {
                storeId: item.storeId,
                ...item
            }*/
            if(item.categories != -1 && item.categories.length > 0) {
                let categoryArr = [
                    {
                        categoryId : general.getOidObjFromVal(item.categories)
                    }
                ];
                item.categoryArr = categoryArr;
            }
            let apiOptions: any = {
                url: `stores/${item.storeId}/products`,
                data: item
            };
            http.post(apiOptions).then(res => {
                saveProductCallBack(res);
            });
        }
    };

    const updateProduct = () => {
        const isValid = bulkValidate();
        if (isValid) {
            const storeIdVal = item.storeId;
            let filters: any = {
                storeId : general.getOidObjFromVal(storeIdVal)
            };
            filters[idKey] = general.getOidObjFromVal(item.id);
            item.storeId = general.getOidObjFromVal(storeIdVal);
            let inputData = {
                filters,
                updateFields: item
            }
            let apiOptions: any = {
                url: `stores/${storeIdVal}/products`,
                data: inputData
            };
            http.put(apiOptions).then(res => {
                saveProductCallBack(res);
            });
        }
    };

    const saveProductCallBack = (res) => {
        AlertService.showSuccessMsg();
        props.saveCallBack();
    };


    const openPhotoCapture = (item: any) => {
        let photoCaptureConfig = {
            id: item.id,
            //cameraConfig: { url: 'stores/savecameraimage' },
            fileUploadConfig: { url: 'product/savefileimage' }
        };
        setUploadConfig(photoCaptureConfig);
        setPhotoCaptureOpen(true);
    }

    const handlePhotoCaptureClose = () => {
        setPhotoCaptureOpen(false);
    }

    const onUploadCallBack = (res: any) => {
        setPhotoCaptureOpen(false);
        if (item.id > 0) {
            getProductById();
        }
        else {
            let filePath = res.data.filePath;
            let imagePath = general.getImageUrl(filePath);
            handleItemChange({ "imagePath": filePath });
            handleItemChange({ "imageUrl": imagePath });
        }
    }

    const openImages = (item: any) => {
        let config = {
            api: `product/${item.id}/images`,
            title: item.name,
            updateDefaultImageApi: `product/updateDefaultImage`,
            deleteImageApi: `product/deleteImage`,
        };
        setImageConfig(config);
        setImageSlideOpen(true);
    }

    const handleImageSlideClose = () => {
        setImageSlideOpen(false);
        if (item.id > 0) {
            getProductById();
        }
    }

    const handleClose = () => {
        props.handleProductFormClose();
    }

    useEffect(() => {
        //initLookup();
        getCategories();
    }, []);

    return (
        <div className="productform-ui">
            <div className="boxwidth scroll-ui">

                <div className="row title-font mx-auto custom-head">
                    <div className="col-11 pl-3 py-1">
                        <span>Item Form</span>
                    </div>
                    <div className="col-1 float-right pointer pt-1" onClick={handleClose}>
                        <HighlightOffIcon fontSize="small" color="secondary" />
                    </div>
                </div>
                <div className="col-12 mx-auto pt-3 mt-2">
                    <div>
                        <div>
                            <Link className="pointer" onClick={() => openImages(item)}>
                                {!item.imageUrl && <img src={dummyImage} alt="dummy"></img>}
                                {item.imageUrl && <img src={item.imageUrl} alt="prod"></img>}
                            </Link>
                            <div className="text-center camera-align ml-4 pl-5" onClick={() => openPhotoCapture(item)}>
                                <CameraAltIcon color="primary" />
                            </div>
                        </div>
                    </div>
                    <div className="pt-4">
                        <FxInput name="code" variant="outlined" label="Code" size="small"
                            fullWidth
                            value={item.code}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("code", "required") &&
                            <div className="error">Field is required</div>
                        }
                    </div>
                    <div className="pt-4">
                        <FxInput name="name" variant="outlined" label="Name" size="small"
                            fullWidth
                            value={item.name}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("name", "required") &&
                            <div className="error">Field is required</div>
                        }
                    </div>

                    <div className="pt-4">
                        <FxSelect name="categories" variant="outlined" label="Categories"
                            fullWidth
                            options={lookUp.Categories}
                            selectedValue={item.categories}
                            disabled = {item.id.length > 0}
                            valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                    </div>

                    <div className="pt-4">
                        <FxTextarea name="description" variant="outlined" label="Description" size="small"
                            value={item.description} maxRows={5}
                            onValueChange={handleInputChange}
                            fullWidth
                        />
                    </div>

                    <div className="row">
                        <div className="col-4 pt-4">
                            <FxInput name="unitQty" variant="outlined" label="Unit Qty" size="small" fullWidth
                                value={item.unitQty} onValueChange={handleInputChange} type="number" />
                        </div>
                        <div className="col-4 pt-4">
                            <FxSelect name="unit" variant="outlined" label="Unit" fullWidth
                                options={lookUp.ProductUnit}
                                selectedValue={item.unit}
                                valueField="code" displayField="text" size="small" onValueChange={handleInputChange} />
                        </div>
                        <div className="col-4 pt-4">
                            <FxInput name="processingTime" variant="outlined" label="Processing&nbsp;Time(mins)" size="small"
                                value={item.processingTime} type="number"
                                onValueChange={handleInputChange} />
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-4 pt-4">
                            <FxInput name="price" variant="outlined" label="Price" size="small" type="number"
                                value={item.price} onValueChange={handleInputChange} />

                        </div>

                        <div className="col-4 pt-4">
                            <FxInput name="taxPercent" variant="outlined" label="Tax Percent" size="small"
                                value={item.taxPercent} onValueChange={handleInputChange} type="number" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8 pt-4">
                            <FxMultiSelect name="productTags" variant="outlined" label="Tag" fullWidth
                                options={lookUp.StoreTags}
                                selectedValue={item.productTags}
                                valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8 pt-4">
                            <FxMultiSelect name="timingIds" variant="outlined" label="Timings" fullWidth
                                options={lookUp.Timings}
                                selectedValue={item.timingIds}
                                valueField="id" displayField="displayText" size="small" onValueChange={handleInputChange} />
                        </div>
                    </div>

                    <div className="pt-3 row justify-content-between px-3 pb-5">
                        <FormLabel component="legend">Tax Type</FormLabel>
                        <RadioGroup row>
                            {
                                lookUp.TaxType && lookUp.TaxType.length > 0 && lookUp.TaxType.map((row, i) => (
                                    <div key={i}>
                                        <FormControlLabel
                                            name="taxType"
                                            value={row.code}
                                            control={<Radio color="primary" size="small" />}
                                            label={row.text}
                                            checked={row.code === item.taxType}
                                            onChange={handleRadioChange}
                                        />
                                    </div>
                                ))
                            }
                        </RadioGroup>
                        <span className="pt-1">
                            <Checkbox name="isActive" color="primary" size="small" checked={item.isActive}
                                value={item.isActive} onChange={handleCheckbox} />Active
                        </span>
                    </div>
                    <div className="pt-5">
                        <div className="py-1 custom-footer">
                            <span className="float-right pr-4">
                                {
                                    item.id.length == 0 &&
                                    <Button variant="contained" size="small" color="primary" onClick={addProduct}>Save</Button>
                                }
                                {
                                    item.id.length > 0 &&
                                    <Button variant="contained" size="small" color="primary" onClick={updateProduct}>Save</Button>
                                }
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <Dialog
                    open={photoCaptureOpen}
                    onClose={handlePhotoCaptureClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
                </Dialog>
                <Dialog
                    open={imageSlideOpen}
                    onClose={handleImageSlideClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
                </Dialog>
            </div>
        </div>
    )
};


export default ProductForm;