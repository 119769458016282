import React, { useEffect, useState } from 'react';
import './MenuList.scss';
import { Button, Link, Dialog, Card, Drawer, CardHeader } from '@material-ui/core';
import dummyImage from "./../../../assets/images/dummy.png";
import { http, AlertService, FxInput, FxPopper, session, general } from '../../fx-core';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import UploadProducts from '../UploadProducts/UploadProducts';
import CategoryForm from '../CategoryForm/CategoryForm';
import Switch from "react-switch";
import ProductForm from '../ProductForm/ProductForm';
import ProductCustomize from '../ProductCustomize/ProductCustomize';
import { nanoid } from 'nanoid';
import * as _ from 'lodash';

const MenuList = (props: any) => {

    const idKey = general.getIdKey();
    let defaultItem: any = {
        searchText: '',
        searchProdText: ''
    };

    let storeIdVal = session.getItem('storeId');
    const [storeId, setStoreId] = useState(storeIdVal);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ id: 0 });
    const [message, setMessage] = useState(null);
    const [categoryFormOpen, setCategoryFormOpen] = useState(false);
    const [productList, setProductList] = useState([]);
    const [item, setItem] = useState(defaultItem);
    const [imageConfig, setImageConfig] = useState({});
    const [imageSlideOpen, setImageSlideOpen] = useState(false);
    const [productFormOpen, setProductFormOpen] = useState(false);
    const [productId, setProductId] = useState(0);
    const [productCustomizeFormOpen, setProductCustomizeFormOpen] = useState(false);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [loadScreen, setLoadScreen] = useState(null);
    const [parentCategoryId, setParentCategoryId] = useState(0);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        getCategories(item.value);
    };

    const handleInputProdChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        if (item.value != '') {
            getProducts(-1, item.value);
        }
        else {
            getProducts(categoryId, item.value);
        }

    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleSearch = () => {
        loadCurrentPage();
        setSubCategoryList([]);
        setProductList([]);
    };


    const openUploadProducts = () => {
        setUploadDialogOpen(true);
    };

    const onUploadCallBack = () => {
        setParentCategoryId(0);
        setUploadDialogOpen(false);
        loadCurrentPage();
        setSubCategoryList([]);
        setProductList([]);
    }

    const loadCurrentPage = () => {
        getCategories(item.searchText);
    }

    const handleUploadClose = () => {
        setUploadDialogOpen(false);
    }

    const deleteItem = () => {
        if (loadScreen == "parent") {
            deleteCategory();
        }
        else if (loadScreen == "sub") {
            deleteSubCategory();
        }
        else if (loadScreen == "product") {
            deleteProduct();
        }
    }

    // ParentCategory related code starts
    const getCategories = (searchText: any) => {        
        let filters: any = {
            storeId: general.getOidObjFromVal(storeId)
        };
        if(searchText.length > 0) {
            filters["categoryName"] = searchText
        }
        let inputData = {
            filters,
            sorters: {}
        }

        let apiOptions: any = {
            url: `categories/find`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let categories = response.data.items;
            let categoryArr = [];
            for (let item of categories) {
                item.category = item;
                item.id = general.getIdFromObj(item);
                categoryArr.push(item);
            }
            for (let item of categoryArr) {
                item.category.imageUrl = general.getImageUrl(item.category.imagePath);
                if(item.subCategories) {
                    for (let itemObj of item.subCategories) {
                        itemObj.imageUrl = general.getImageUrl(itemObj.imagePath);
                    }
                }
            }
            setCategoryList(categoryArr);
        })
    }

    const openCategoryForm = (id: any = 0) => {
        setCategoryId(id);
        setCategoryFormOpen(true);
        setAnchorEl(null);
    };

    const handleCategoryFormClose = () => {
        setCategoryFormOpen(false);
        getCategories(item.searchText);
    };

    const handleSelectedItem = (item: any) => {
        setSelectedItem(item);
    };

    const confirmDelete = (item) => {
        setLoadScreen("parent");
        handleSelectedItem(item);
        setMessage("Are you sure to delete " + item.categoryName + "?");
        setDeleteOpen(true);
        setAnchorEl(null);
    };

    const closeCancelDialog = () => {
        setDeleteOpen(false);
    }

    const deleteCategory = () => {
        let filters: any = {};
        filters[idKey] = general.getOidObjFromVal(selectedItem.id);

        let inputData: any = {
            filters
        };

        let apiOptions: any = {
            url: `stores/${storeId}/categories/delete`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            deleteCategoryCallBack(res);
        });
    };

    const deleteCategoryCallBack = (res) => {
        AlertService.showSuccessMsg();
        setDeleteOpen(false);
        getCategories(item.searchText);
    };

    const openSubCategoryList = (id: any) => {
        setParentCategoryId(id);
        setCategoryId(0);
        setLoadScreen("sub");
        setProductList([]);
        getSubCategories(id);
    }

    const handleCategoryChange = (item) => {
        updateCategory(item.id, item.isActive);
    }

    const updateCategory = (id, isActive) => {
        let filters: any = {};
        filters[idKey] = general.getOidObjFromVal(id);

        let inputData: any = {
            filters,
            updateFields: {
                isActive: !isActive
            }
        };

        let apiOptions: any = {
            url: `stores/${storeId}/categories`, 
            data: inputData
        };
        http.put(apiOptions).then(res => {
            getCategories(item.searchText);
        });
    };

    // ParentCategory related code ends

    // SubCategory related code starts

    const getSubCategories = (id: any) => {
        let inputData = { storeId: storeId, id: id }

        let apiOptions: any = {
            url: 'category/query/byparentcatgId',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let categories = response.data;
            if (categories.length == 0) {
                setSubCategoryList([]);
                getProducts(id, '');
            }
            else {
                for (let item of categories) {
                    item.imageUrl = general.getImageUrl(item.imagePath);
                }
                setSubCategoryList(categories);
            }
        })
    }

    const openProductList = (catgId: any) => {
        setCategoryId(catgId);
        setLoadScreen("product");
        getProducts(catgId, '');
    }

    const deleteSubCategory = () => {
        let inputData: any = {
            id: selectedItem.id
        };

        let apiOptions: any = {
            url: `category/${inputData.id}`
        };
        http.delete(apiOptions).then(res => {
            deleteSubCategoryCallBack(res);
        });
    };

    const deleteSubCategoryCallBack = (res) => {
        AlertService.showSuccessMsg();
        setDeleteOpen(false);
        getCategories(item.searchText);
    };

    const confirmSubDelete = (item) => {
        setLoadScreen("sub");
        handleSelectedItem(item);
        setMessage("Are you sure to delete " + item.categoryName + "?");
        setDeleteOpen(true);
    };

    const handleSubChange = (item) => {
        updateSubCategory(item.id);
    }

    const updateSubCategory = (id) => {
        let inputData: any = {
            id: id
        };

        let apiOptions: any = {
            url: `category/toggle`, data: inputData
        };
        http.put(apiOptions).then(res => {
            getCategories(item.searchText);
        });
    };

    // SubCategory related code ends

    // Productlist related code starts
    const getProducts = (id: any, searchTxt: any) => {
        let filters: any = {
            storeId: general.getOidObjFromVal(storeId)
        };
        if(id.length > 0) {
            filters["categoryId"] = general.getOidObjFromVal(id);
        }
        if(searchTxt.length > 0) {
            filters["name"] = searchTxt
        }
        //let inputData = { /*storeId: storeId, categoryId: id, name: searchTxt*/ }
        let inputData = {
            filters,
            sorters: {}
        };
        let apiOptions: any = {
            url: `products/${storeId}/subaction`,
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let productsList = response.data.products;
            let categProds = response.data["category-products"];
            let products = [];
            if(categProds && categProds.length > 0) {
                for (let item of categProds) {
                    const prodId = general.getOidValFromKey(item.productId);
                    const prodItem = _.find(productsList, (p) => {
                        const pid = general.getIdFromObj(p);
                        if(pid == prodId) {
                            return p;
                        }
                    });
                    if(prodItem) {
                        prodItem.imageUrl = general.getImageUrl(prodItem.imagePath);
                        prodItem.id = general.getIdFromObj(prodItem);
                        products.push(prodItem);
                    }
                }
            }
            setProductList(products);
        })
    }

    const openProductForm = (id: any = 0) => {
        setProductId(id);
        setProductFormOpen(true);
        setAnchorEl(null);
    };

    const handleProductFormClose = () => {
        setProductFormOpen(false);
        getProducts(categoryId, '');
    };

    const confirmDeleteProduct = (item) => {
        setLoadScreen("product");
        handleSelectedItem(item);
        setMessage("Are you sure to delete " + item.name + "?");
        setDeleteOpen(true);
        setAnchorEl(null);
    };

    const deleteProduct = () => {
        let filters: any = {};
        filters[idKey] = general.getOidObjFromVal(selectedItem.id);

        let inputData: any = {
            filters
        };

        let apiOptions: any = {
            url: `stores/${storeId}/products/delete`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            deleteProductCallBack();
        });
    };

    const deleteProductCallBack = () => {
        AlertService.showSuccessMsg();
        setDeleteOpen(false);
        getProducts(categoryId, '');
    };

    const openImages = (item: any) => {
        let config = {
            api: `product/${item.id}/images`,
            title: item.name
        };
        setImageConfig(config);
        setImageSlideOpen(true);
    }

    const handleImageSlideClose = () => {
        setImageSlideOpen(false);
    }

    const saveCallBack = (res) => {
        setProductFormOpen(false);
        getProducts(categoryId, '');
    }


    const openProductCustomForm = (id: any) => {
        setProductId(id);
        setProductCustomizeFormOpen(true);
        setAnchorEl(null);
    }

    const handleProductCustomForm = () => {
        setProductCustomizeFormOpen(false);
    }

    const toggleProduct = (item) => {
        updateProduct(item.id, item.isActive);
    }

    const updateProduct = (id, isActive) => {
        let filters: any = {};
        filters[idKey] = general.getOidObjFromVal(id);

        let inputData: any = {
            filters,
            updateFields: {
                isActive: !isActive
            }
        };

        let apiOptions: any = {
            url: `stores/${storeId}/products`, 
            data: inputData
        };
        http.put(apiOptions).then(res => {
            getProducts(categoryId, '');
        });
    };
    // Productlist related code ends

    var cardStyle = {
        // display: 'block',
        height: '500px',
        overflow: "auto"

    }

    useEffect(() => {
        setLoadScreen("parent");
        getCategories(item.searchText);
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const body = (
        <div className="content-container menulist-ui">
            <div className="scroll-ui">
            <div className="titlefont mt-n4 pl-4 py-2 custom-head">
            <div className="row col-sm-12 pr-0">
                <span className="col-sm-2 pl-4 menuhead-ui">
                    Menu List
                </span>

                <span className="col-sm-3 button-align">
                    <FxInput name="searchText" variant="outlined" label="Search Category / Sub Category"
                        fullWidth
                        size="small"
                        value={item.searchText}
                        onValueChange={handleInputChange}
                    />
                </span>

                <span className="col-sm-3 button-align">
                    <FxInput name="searchProdText" variant="outlined" label="Search Item"
                        fullWidth
                        size="small"
                        value={item.searchProdText}
                        onValueChange={handleInputProdChange}
                    />
                </span>

                <span className="col-sm-3 row pr-0 justify-content-between">
                    <span className="pt-1">
                    </span>
                    <span className="upload-ui pr-0">
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<UploadIcon color="primary" />}
                            onClick={openUploadProducts}
                        >
                            <span className="upload-ui">Upload Items</span>
                        </Button>
                    </span>
                </span>
            </div>
           </div>

            <div className="col-sm-12 p-0 pt-3 row mx-auto">
                <div className="col-sm-4 p-0 px-1">
                    <div className="pt-4">
                        <div className="card">
                            {/* <Card style={cardStyle}>  */}
                            <div className="title-font custom-head py-1">
                                <div className="row justify-content-between">
                                <span className="col-10 pl-4">
                                    <span>Category | {categoryList.length}</span>
                                </span>
                                <span className="col-2 text-right pointer pr-4" onClick={() => openCategoryForm(0)}>
                                    <AddCircleIcon color="primary" />
                                </span>
                            </div>
                            </div>

                            <div className="pt-2 scrollable">
                                {
                                    categoryList.map((item) => (
                                        <div className={(parentCategoryId > 0 && parentCategoryId == item.category.id ? "selected" : "unselected")}>
                                            <div className="row col-sm-12 pb-2">

                                                <div className="col-sm-2" onClick={() => openProductList(item.category.id)}>
                                                    <div>
                                                        <div className="pointer pt-2" onClick={() => openProductList(item.id)}>
                                                            {!item.category.imageUrl && <img src={dummyImage} alt="dummy"></img>}
                                                            {item.category.imageUrl && <img src={item.category.imageUrl} alt="prod"></img>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 pt-4 pointer text-ui" onClick={() => openProductList(item.category.id)}>
                                                    <span className={(categoryId > 0 && categoryId == item.category.id ? "selected" : "unselected")}>
                                                        {item.category.categoryName}
                                                    </span>
                                                </div>
                                                <div className="col-sm-5 row pr-0 pt-4 edit-ui">
                                                    <div>
                                                        <span>
                                                            <Switch onChange={() => handleCategoryChange(item.category)} checked={item.category.isActive}
                                                                checkedIcon={false} handleDiameter={12} width={35} height={20}
                                                                className="togglestyle" />
                                                        </span>
                                                        <span className="pl-4">
                                                            <FxPopper iconKey="fas fa-ellipsis-v" popupId={`category-${nanoid}`}>
                                                                <div className="popper-menu-item" onClick={() => openCategoryForm(item.category.id)}>Edit</div>
                                                                <div className="popper-menu-item" onClick={() => confirmDelete(item.category)}>Delete</div>
                                                            </FxPopper>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 pr-0 float-right">
                                                    {item.subCategories && item.subCategories.length > 0 &&
                                                        item.subCategories.map((item) => (
                                                            <div className={(categoryId > 0 && categoryId == item.id ? "selected" : "unselected")}>
                                                                <div className="row col-sm-12 pr-0 pb-2">
                                                                    <div className="col-sm-2">
                                                                        <div>
                                                                            <div className="pointer pt-2">
                                                                                {!item.imageUrl && <img src={dummyImage} alt="dummy"></img>}
                                                                                {item.imageUrl && <img src={item.imageUrl} alt="prod"></img>}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-5 pt-4 pointer text-ui">
                                                                        <span onClick={() => openProductList(item.id)}
                                                                            className={(categoryId > 0 && categoryId == item.id ? "selected" : "unselected")}>
                                                                            {item.categoryName}</span>
                                                                    </div>
                                                                    <div className="col-sm-2"></div>
                                                                    <div className="col-sm-3 pr-0 pt-4 edit-ui">
                                                                        <div>
                                                                            <span>
                                                                                <Switch onChange={() => handleSubChange(item)} checked={item.isActive}
                                                                                    checkedIcon={false} handleDiameter={12} width={35} height={20}
                                                                                    className="togglestyle" />
                                                                            </span>
                                                                            <span className="pl-4">
                                                                                <FxPopper iconKey="fas fa-ellipsis-v" popupId={`subcategory-${nanoid}`}>
                                                                                    <div className="popper-menu-item" onClick={() => openCategoryForm(item.id)}>Edit</div>
                                                                                    <div className="popper-menu-item" onClick={() => confirmSubDelete(item)}>Delete</div>
                                                                                </FxPopper>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    categoryList && categoryList.length == 0 &&
                                    <span className="no-ui">No categories found</span>
                                }

                            </div>
                        </div>
                        {/* </Card> */}
                    </div>
                </div>

                <div className="col-sm-4 p-0 px-1">
                    <div className="pt-4">
                        <Card style={cardStyle}>
                            <div className="row justify-content-between title-font custom-head py-1">
                                <span className="col-11 pl-4">
                                    <span>Items | {productList.length}</span>
                                </span>
                                <span className="col-1 float-right pointer" onClick={() => openProductForm()}>
                                    <AddCircleIcon color="primary" />
                                </span>
                            </div>

                            <div>
                                {productList && productList.length > 0 &&
                                    productList.map((item) => (
                                        <div className="row col-sm-12 pb-2">
                                            <div className="col-sm-2">
                                                <div className="pt-2">
                                                    <Link className="pointer" onClick={() => openImages(item)}>
                                                        {!item.imageUrl && <img src={dummyImage} alt="dummy"></img>}
                                                        {item.imageUrl && <img src={item.imageUrl} alt="prod"></img>}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-sm-5 pt-4 text-ui">
                                                <span> {item.name}</span>
                                                {item.unitQty > 0 &&
                                                    <span> {item.unitQty}</span>
                                                }
                                                <span> {item.unit}</span>
                                            </div>

                                            <div className="col-sm-5 edit-ui pt-4">
                                                <div>
                                                    <span>
                                                        <Switch onChange={() => toggleProduct(item)} checked={item.isActive}
                                                            checkedIcon={false} handleDiameter={12} width={35} height={20}
                                                            className="togglestyle" />
                                                    </span>
                                                    <span className="pl-4" >
                                                        <FxPopper iconKey="fas fa-ellipsis-v" popupId={`product-${nanoid}`}>
                                                            <div className="popper-menu-item" onClick={() => openProductForm(item.id)}>Edit</div>
                                                            <div className="popper-menu-item" onClick={() => confirmDeleteProduct(item)}>Delete</div>
                                                            <div className="popper-menu-item" onClick={() => openProductCustomForm(item.id)}>Customize</div>
                                                        </FxPopper>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    productList && productList.length == 0 &&
                                    <span className="no-ui pt-5">No products found</span>
                                }
                            </div>
                        </Card>
                    </div>

                </div>
                <div className="col-sm-4 p-0 px-1">
                </div>
            </div>
            </div>
            <div>
                <Dialog
                    open={deleteOpen}
                    onClose={closeCancelDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ConfirmDialog message={message} onConfirmCallback={deleteItem} handleClose={closeCancelDialog} />
                </Dialog>
                <Dialog
                    open={imageSlideOpen}
                    onClose={handleImageSlideClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
                </Dialog>

                <Drawer anchor={"right"} open={productCustomizeFormOpen} onClose={handleProductCustomForm}>
                    <ProductCustomize productId={productId} handleProductCustomForm={handleProductCustomForm} />
                </Drawer>
                <Drawer anchor={"right"} open={uploadDialogOpen} onClose={handleUploadClose}>
                    <UploadProducts onUploadCallBack={onUploadCallBack} handleUploadClose={handleUploadClose} />
                </Drawer>
                <Drawer anchor={"right"} open={productFormOpen} onClose={handleProductFormClose}>
                    <ProductForm productId={productId} storeId={storeId} saveCallBack={saveCallBack}
                        handleProductFormClose={handleProductFormClose} />
                </Drawer>
                <Drawer anchor={"right"} open={categoryFormOpen} onClose={handleCategoryFormClose}>
                    <CategoryForm storeId={storeId} categoryId={categoryId}
                        handleCategoryFormClose={handleCategoryFormClose} />
                </Drawer>
            </div>
        </div>
    );

    return (
        <div>
            {body}
        </div>
    );
};

export default MenuList;