import moment from 'moment';
import { Constants } from './constants';

export const formatService = {
    getCurrentDate: getCurrentDate,
    getCurrentTime: getCurrentTime,
    parseDate: parseDate,
    getCurrentDateForServer: getCurrentDateForServer,
    getDateStringForServer: getDateStringForServer,
    getNextDate: getNextDate,
    getFormattedTimeForServer: getFormattedTimeForServer,
    getConvertedTime: getConvertedTime,
    getConvertedDate: getConvertedDate,
    getTimeDisplay: getTimeDisplay,
    getCurrentDateTime: getCurrentDateTime,
    isBefore: isBefore,
    addDays: addDays,
    substractDays: substractDays,
    getConvertToDateFormat: getConvertToDateFormat,
    getDateStringForDisplay: getDateStringForDisplay,
    getCurrentDay : getCurrentDay,
    getCurrentTimeForServer : getCurrentTimeForServer
}

const predefinedMap: any = {
    currentDate: {},
    dayPlusSeven: { "addValue": 7, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" },
    dayPlusSix: { "addValue": 6, "addPart": "day", "startOrEnd": "", "startOrEndPart": "" }
};

function parseDate(strInput: any) {
    var dateOptions = predefinedMap[strInput];
    if (dateOptions) {
        return getComputedDate(dateOptions);
    }

    var result = "";
    if (strInput) {
        result = moment(strInput, [Constants.ServerDateFormat, Constants.DateFormat]).format(Constants.DateFormat);
    }
    return result;
}

function getComputedDate(opts: any) {
    var result = moment();
    if (opts.addPart) {
        result = result.add(opts.addValue || 0, opts.addPart)
    }
    if (opts.startOrEnd) {
        result = opts.startOrEnd == 'start' ? result.startOf(opts.startOrEndPart) : result.endOf(opts.startOrEndPart);
    }
    if (opts.addDayValue) {
        result = result.day(opts.addDayValue)
    }
    return result.format(Constants.DateFormat);
}

function getCurrentDateForServer() {
    let result = moment().format(Constants.ServerDateFormat);
    return result;
}

function getDateStringForServer(inputDate: any) {
    let result = moment(inputDate, [Constants.ServerDateFormat, Constants.DateFormat]).format(Constants.ServerDateFormat);
    return result;
}

function getNextDate(inputDate: any) {
    let result = moment(inputDate).add(1, 'days').format(Constants.ServerDateFormat)
    return result;
}

function getCurrentDate() {
    let result = moment().format(Constants.DateFormat);
    return result;
}

function getCurrentTime() {
    let result = moment().format(Constants.TimeFormat12Hour);
    return result;
}

function getConvertedTime(inputDate: any) {
    let result = moment(inputDate, ["hh:mm A"]).format(Constants.ServerTimeFormat);
    return result;
}

function getFormattedTimeForServer(inputDate: any) {
    let result = moment(inputDate, ["hh:mm A"]).format(Constants.ServerTimeFormat);
    return result;
}

function getConvertedDate(inputDate: any) {
    let result = moment(inputDate).format(Constants.ServerDateFormat);
    return result;
}

function getTimeDisplay(inputDate: any) {
    if(inputDate) {
        let result = moment(inputDate,["hh:mm:ss"]).format(Constants.TimeFormat12Hour);
        return result;
    }
    return "";
}

function getCurrentDateTime() {
    var result = "";
    result = moment().format(Constants.DateTimeFormat);
    return result;
}

function isBefore(startDate:any, endDate:any) {
    return moment(endDate).isBefore(startDate);
 }

 function addDays(inputDate:any,number:any) {
    var result = "";
    result = moment(inputDate).add(number, 'days').format(Constants.ServerDateFormat);
    return result;
 }

 function substractDays(inputDate:any,number:any) {
    var result = "";
    result = moment(inputDate).add(-number, 'days').format(Constants.ServerDateFormat);
    return result;
 }

 function getConvertToDateFormat(inputDate:any) {
    let result = moment(inputDate,[Constants.DateFormat]).format(Constants.DateFormat);
    return result;
}

function getDateStringForDisplay(inputDate: any) {
    let result = moment(inputDate, [Constants.DateTimeFormat]).format(Constants.DateFormat);
    return result;
}

function getCurrentDay() {
    var result = moment().day();
    return result;
}

function getCurrentTimeForServer() {
    let result = moment().format(Constants.ServerTimeFormat);
    return result;
}
