export const Tab = {
  key: 'Tab',
  which: 9,
  keyCode: 9,
};

export const Enter = {
  key: 'Enter',
  which: 13,
  keyCode: 13,
};

export const Escape = {
  key: [
    'Escape',
    // IE11 Escape
    'Esc',
  ],
  which: 27,
  keyCode: 27,
};

export const Space = {
  key: ' ',
  which: 32,
  keyCode: 32,
};

export const PageUp = {
  key: 'PageUp',
  which: 33,
  keyCode: 33,
};

export const PageDown = {
  key: 'PageDown',
  which: 34,
  keyCode: 34,
};

export const End = {
  key: 'End',
  which: 35,
  keyCode: 35,
};

export const Home = {
  key: 'Home',
  which: 36,
  keyCode: 36,
};

export const ArrowLeft = {
  key: 'ArrowLeft',
  which: 37,
  keyCode: 37,
};

export const ArrowUp = {
  key: 'ArrowUp',
  which: 38,
  keyCode: 38,
};

export const ArrowRight = {
  key: 'ArrowRight',
  which: 39,
  keyCode: 39,
};

export const ArrowDown = {
  key: 'ArrowDown',
  which: 40,
  keyCode: 40,
};
