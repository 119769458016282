import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { ProgressProvider } from './components/fx-core';
import Header from './components/header/Header';
import Login from './components/login/Login';
import RestaurantList from './components/superadmin/RestaurantList/RestaurantList';
import RestaurantFormContainer  from './components/superadmin/RestaurantFormContainer/RestaurantFormContainer';
import StoreProfile from './components/admin/StoreProfile/StoreProfile';
import MenuList from './components/admin/MenuList/MenuList';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#509E2F"
    },
    secondary: {
      main: "#ffffff"
    }
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif',].join(','),
  },
});

export const App = () => {
  const hasLogin = window.location.pathname !== "/";
  console.log('window.location.pathname=', window.location.pathname);

  return (
    <MuiThemeProvider theme={theme}>
      <ProgressProvider>
        <Router basename="admin">
          <Header />
          <div className="justify-content-center">
            <Switch>
              <Route exact={true} path="/" component={Login} />
              <Route path="/restaurantlist" component={RestaurantList} />
              <Route path="/restaurantform" component={RestaurantFormContainer} />
              <Route path="/storeprofile" component={StoreProfile} />
              <Route path="/menulist" component={MenuList} />
            </Switch>
          </div>
          <ToastContainer />
        </Router>
      </ProgressProvider>
    </MuiThemeProvider>
  );
}

export default App;
