import React from 'react';
import './Leftmenu.scss';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IconPeople from '@material-ui/icons/People';
import IconExitToApp from '@material-ui/icons/ExitToApp';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { session } from "../fx-core/helpers/sessionService";
import IconPerson from '@material-ui/icons/Person';
import IconList from '@material-ui/icons/List';

const Leftmenu = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();
  
  if (location.pathname == '/' || location.pathname == '/login') {
    return <></>;
  } else {
    let menus: any = [];
    const roleCode = session.getItem("roleCode");
    if(roleCode == "SUPERADMIN") {
      menus = [
        { title: 'Stores', route: '/restaurantlist', icon: <IconPeople /> },
        { title: 'Logout', route: '/', icon: <IconExitToApp /> }
      ];
    }
    else {
      menus = [
        //{ title: 'Profile', route: '/storeprofile', icon: <IconPerson /> },        
        { title: 'Menu List', route: '/menulist', icon: <IconList /> },
        { title: 'Logout', route: '/', icon: <IconExitToApp /> }
      ];
    }

    const openMenu = (menu) => {
      if (menu.title = "Logout") {
        localStorage.clear();
      }
      history.push(menu.route);
    }

    return (
      <List className={classes.menuheight} disablePadding>
        { menus.map((menu: any) => (
          <ListItem button className={classes.menuItem} onClick={() => openMenu(menu)}>
            <ListItemIcon className={classes.menuItemIcon}>
              {menu.icon}
            </ListItemIcon>
            <ListItemText primary={menu.title} />
          </ListItem>
        ))
        }
      </List>
    );
  }
}

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
  createStyles({
    menuheight: {
      background: '#ffffff',
      color: '#000000',
      width: drawerWidth,
      height: 690,         
    },
    menuItem: {
      width: drawerWidth,      
    },
    menuItemIcon: {
      color: '#000000',
    }
  }),
)

export default Leftmenu;