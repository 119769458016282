import React from 'react';
import { IconButton, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FxSelect.styles.scss';

export const FxSelect = (props:any) => {
      if (props.visibility === false) {
        return <></>;
    }
    const { name, onValueChange, validations, variant, size = "small" } = props;

    const onChange = (event: any, value: any, reason: string) => {
        const data = { name, value: value[props.valueField] };
        if (onValueChange) onValueChange(data);
    };

    const getSelectedItem = () => {
        const item = props.options.find((opt: any) => {
            if (opt[props.valueField] == props.selectedValue) return opt;
        });
        return item || { [props.displayField]: "" };
    };
  
    let className = props.className || "";

    return (
        <Autocomplete
            className={className}
            value={getSelectedItem()}
            options={props.options}
            getOptionLabel={(option: any) => option[props.displayField]}
            disableClearable
            disabled={props.disabled}
            onChange={onChange}
            size={size}
            style={props.style}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.hideCaption === true ? null : props.label}
                    hiddenLabel={props.hideCaption}
                    required={props.required}
                    placeholder={props.placeHolderText}
                    variant={variant == 'outlined' ? 'outlined' : 'standard'}
                />
            )}
        />
    );
    };

