import React, { useEffect, useState } from 'react';
import './RestaurantList.scss';
import { Button, Container, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Link, Dialog, Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import IconPhone from '@material-ui/icons/Phone';
import { useHistory } from "react-router-dom";
import { http, AlertService, FxInput, FxSelect, general } from '../../fx-core';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';

const RestaurantList = () => {
    const history = useHistory();

    let defaultFilter: any = {
        verificationStatusId: 2,
        storeName: ''
    };

    let defaultPager: any = {
        startIndex: 0,
        pageCount: 0,
        pageNo: 1,
        totalRecords: 0,
        pagerInfo: '',
        pageSize: 25
    };
    let defaultLookUp:any = {
        VerificationStatus:useState([])
    };

    const [lookUp, setLookUp] = useState(defaultLookUp);
    const [items, setItems] = useState([]);
    const [verifyOpen, setVerifyOpen] = useState(false);
    const [completeOpen, setCompleteOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ id: "", verificationStatusId:0 });
    const [filter, setFilter] = useState(defaultFilter);
    const [pager, setPager] = useState(defaultPager);
    const [message, setMessage] = useState(null);
    const [action, setAction] = useState(null);

    const computePagerData = (data, pagerOptions) => {
        let totalRecords = data.pagerResult.totalCount;
        let startNo = pagerOptions.startIndex + 1;
        startNo = totalRecords === 0 ? 0 : startNo;

        let endNo = pagerOptions.startIndex + pager.pageSize;
        endNo = endNo > totalRecords ? totalRecords : endNo;

        let pageCount = Math.ceil(totalRecords / pager.pageSize);
        let pagerInfo = `Showing ${startNo} - ${endNo} of ${totalRecords} items`;
        handlePagerChange({
            totalRecords, pageCount, pagerInfo,
            startIndex: pagerOptions.startIndex, pageNo: pagerOptions.pageNo
        });
    };

    const pageChanged = (event: object, page: number) => {
        getRestaurants({ pager: { pageNo: page } });
    };

    const handlePagerChange = (data) => {
        setPager(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleInputChange = (item) => {
        handleFilterChange({ [item.name]: item.value });
        let options = { pager: { pageNo: 1 }, filter: { [item.name]: item.value } };
        getRestaurants(options);
    };

    const handleFilterChange = (data) => {
        setFilter(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleLookUp = (data) => {
        setLookUp(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getRestaurants = (options: any = {}) => {
        let startIdx = 0;
        let selectedStatusId = filter.verificationStatusId;
        if (options && options.pager) {
            startIdx = options.pager.pageNo === 1 ? 0 : ((options.pager.pageNo - 1) * pager.pageSize);
        }
        if (options && options.filter) {
            selectedStatusId = options.filter.verificationStatusId ? options.filter.verificationStatusId : filter.verificationStatusId;
        }

        let filters: any = { businessTypeId: 1, verificationStatusId: selectedStatusId };
        if(filter.storeName.length > 0) {
            filters.storeName = filter.storeName;
        }
        let inputData = {
            filters: filters
            //startIndex: startIdx,
            //pageSize: pager.pageSize,
        };

        let apiOptions: any = {
            url: 'stores/find',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            console.log(response.data.items);
            setItems(response.data.items);
            computePagerData(response.data, { startIndex: startIdx, ...options.pager });

        })
    }

    const showRestaurantForm = (item: any) => {
        const id = general.getIdFromObj(item);
        history.push({
            pathname: '/restaurantform',
            state: { id: id }
        });
    };

    const handleSelectedItem = (item) => {
        let selItem: any = {
            id: general.getIdFromObj(item)
        };
        setSelectedItem(selItem);
    };

    const openVerifyDialog = (item) => {
        handleSelectedItem(item);
        setMessage("Are you sure to mark as verified?");
        setAction("verify");
        setVerifyOpen(true);
    };

    const closeVerifyDialog = () => {
        handleSelectedItem({ id: "" });
        setVerifyOpen(false);
    }

    const updateVerifyStatus = (comments) => {
        /*let inputData: any = {
            id: selectedItem.id,
            verificationStatusId: selectedItem.verificationStatusId , //Verify
            verificationComments: comments
        };

        let apiOptions: any = {
            url: `store/updatestatus`,
            data: inputData
        };*/

        let inputData: any = {
            filters: general.getFiltersObjById(selectedItem.id),
            updateFields: {
                verificationStatusId: 3,
                verificationComments: comments
            }
        };

        let apiOptions: any = {
            url: `stores/${selectedItem.id}/status`,
            data: inputData
        };

        http.put(apiOptions).then(res => {
            updateVerifyStatusCallBack(res);
        });
    };

    const updateVerifyStatusCallBack = (res) => {
        AlertService.showSuccessMsg();
        closeVerifyDialog();
        getRestaurants();
    };

    const openCompleteDialog = (item) => {
        handleSelectedItem(item);
        setMessage("Are you sure to mark as completed?");
        setAction("complete");
        setCompleteOpen(true);
    };

    const closeCompleteDialog = () => {
        handleSelectedItem({ id: 0 });
        setCompleteOpen(false);
    };

    const updateCompleteStatus = () => {
        /*let inputData: any = {
            id: selectedItem.id,
            verificationStatusId: selectedItem.verificationStatusId 
        };

        let apiOptions: any = {
            url: `store/updatestatus`,
            data: inputData
        };*/

        let inputData: any = {
            filters: general.getFiltersObjById(selectedItem.id),
            updateFields: {
                verificationStatusId: 2,
            }
        };

        let apiOptions: any = {
            url: `stores/${selectedItem.id}/status`,
            data: inputData
        };        
        http.put(apiOptions).then(res => {
            updateCompleteStatusCallBack(res);
        });
    };

    const updateCompleteStatusCallBack = (res) => {
        AlertService.showSuccessMsg();
        closeCompleteDialog();
        getRestaurants();
    };

    const initLookup = () => {
        /*let inputData = {
            lookups: {
                VerificationStatus: { default: false }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            handleLookUp({"VerificationStatus":response.data.VerificationStatus});
        });*/
        const VerificationStatus = 
        [
            {
                "id": 1,
                "text": "Pending",
                "code": "Pending",
                "isDefault": false,
                "attributes": null
            },
            {
                "id": 2,
                "text": "Completed",
                "code": "Completed",
                "isDefault": false,
                "attributes": null
            },
            {
                "id": 3,
                "text": "Verified",
                "code": "Verified",
                "isDefault": false,
                "attributes": null
            }
        ];
        handleLookUp({VerificationStatus: VerificationStatus});
    }

    useEffect(() => {
        initLookup();
        let options = { pager: { pageNo: 1 }, filter: filter };
        getRestaurants(options);
    }, []);

    return (
        <div className="content-container">
            <div className="scroll-ui">
            <div className="title-font mt-n3 py-2">
                <span className="pl-4">Stores</span>
            </div>
            <div className="row col-12 mt-n1 pt-4 justify-content-between">
                <div className="col-4 search mt-n1 pl-1">
                    <FxInput name="storeName" variant="outlined" label="Search Name"
                        size="small"
                        value={filter.storeName}
                        onValueChange={handleInputChange}
                    />
                </div>
                <div className="col-4 pr-4">
                    <FxSelect name="verificationStatusId" variant="outlined" label="Verification Status"
                        options={lookUp.VerificationStatus}
                        selectedValue={filter.verificationStatusId}
                        valueField="id"
                        displayField="text"
                        size="small"
                        onValueChange={handleInputChange} />
                </div>
            </div>
            <div className="row col-12 mt-1">
                <div className="col-8"></div>
                <div className="col-4">
                    <span className="float-right pt-2 pr-2">
                        <Button variant="contained" size="small" color="primary" onClick={showRestaurantForm}>Add</Button>
                    </span>
                </div>
            </div>
            <div className="row col-12">
                <Container maxWidth="xl" className="mt-2">
                    <TableContainer component={Paper}>
                        <Table stickyHeader className="table table-striped">
                            <TableHead>
                                <TableRow>
                                <TableCell width="15%" className="table-head table-font">Code</TableCell>
                                    <TableCell width="15%" className="table-head table-font">Name</TableCell>
                                    <TableCell width="10%" className="table-head table-font">Mobile</TableCell>
                                    <TableCell width="10%" className="table-head table-font">Email</TableCell>
                                    <TableCell className="table-head table-font">Business Type</TableCell>
                                    <TableCell width="20%" className="table-head table-font">Address</TableCell>
                                    <TableCell width="10%" className="table-head table-font">Verification Status</TableCell>
                                    <TableCell className="table-head table-font">
                                        <span className="alignright">Actions</span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    items.map((row, i) => (
                                        <TableRow key={i}>
                                             <TableCell>
                                                <div className="table-font">
                                                    <span>{row.storeCode}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="table-font">
                                                    <span>{row.storeName}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <span className="mobileno-color table-font">
                                                        <Link href="tel:{{row.mobile}}">
                                                            <IconPhone fontSize="small" />
                                                            <span>{row.mobile}</span>
                                                        </Link>
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div> <span className="table-font">{row.email}</span></div>
                                            </TableCell>

                                            <TableCell>
                                                <div className="table-font pr-5">
                                                    {row.businessType}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div> <span className="table-font">{row.address}</span></div>
                                            </TableCell>
                                            <TableCell>
                                                <div> <span className="table-font">{row.verificationStatus}</span></div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="btn-style alignright">
                                                    <span className="table-font">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={() => showRestaurantForm(row)}> Edit
                                                    </Button>
                                                    </span>
                                                    {
                                                        row.verificationStatusId == 2 &&
                                                        <span className="table-font ml-1">
                                                            <Button variant="contained" size="small" color="primary"
                                                                onClick={() => openVerifyDialog(row)}> Verify
                                                        </Button>
                                                        </span>
                                                    }
                                                    {
                                                        row.verificationStatusId == 3 &&
                                                        <span className="table-font ml-1">
                                                            <Button variant="contained" size="small" color="primary"
                                                                onClick={() => openCompleteDialog(row)}>Complete
                                                        </Button>
                                                        </span>
                                                    }
                                                    

                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </div>
            <div className="container pt-2 row col-12 justify-content-between">
                <div className="bottom-info pt-3 pl-4">{pager.pagerInfo}</div>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    flex={1}
                    padding={1}
                    paddingRight={1}
                >
                    <Pagination
                        page={pager.pageNo}
                        count={pager.pageCount}
                        shape="rounded"
                        color="primary"
                        showFirstButton
                        showLastButton
                        boundaryCount={2}
                        onChange={pageChanged}
                    />
                </Box>
            </div>
            </div>
            <Dialog
                open={completeOpen}
                onClose={closeCompleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} action={action} onConfirmCallback={updateCompleteStatus} handleClose={closeCompleteDialog} />
            </Dialog>
            <Dialog
                open={verifyOpen}
                onClose={closeVerifyDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ConfirmDialog message={message} action={action} onConfirmCallback={updateVerifyStatus} handleClose={closeVerifyDialog} />
            </Dialog>
        </div>
    )
};

export default RestaurantList;