import React, { useEffect, useState } from 'react';
import './ProductCustomize.scss';
import { Button, Card, Dialog, Link, ListItemAvatar } from '@material-ui/core';
import dummyImage from "./../../../assets/images/dummy.png";
import { http, AlertService, FxInput, FxCurrency, general, FxPopper, FxSelect } from '../../fx-core';
import ConfirmDialog from '../../general/ConfirmDialog/ConfirmDialog';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from "@material-ui/core/Checkbox";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { nanoid } from 'nanoid';
import PreviewGroup from '../PreviewGroup/PreviewGroup';
import * as _ from 'lodash';
import InfoIcon from '@material-ui/icons/Info';

const ProductCustomize = (props: any) => {

    let defaultItem: any = {
        id: 0,
        productId: props.productId,
        groupName: "",
        groupTypeId: 0,
        groupPrice: 0,
        groupTemplateId: -1,
        maxCount: 0,
        minCount: 0,
        price: 0,
        description: "",
        isDefault: true,
        imagePath: "",
        imageUrl: null
    };
    let defaultLookUp: any = {
        GroupTemplate: useState([])
    };
    const [lookUp, setLookUp] = useState(defaultLookUp);
    const [customizedList, setCustomizedList] = useState([]);
    const [inputItem, setInputItem] = useState(defaultItem);
    const [customizeFormOpen, setCustomizeFormOpen] = useState(false);
    const [showAddDetailForm, setAddShowDetailForm] = useState(false);
    const [groupType, setGroupType] = useState([{ "id": 1, "name": "Single" }, { "id": 2, "name": "Multi" }]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ id: 0 });
    const [message, setMessage] = useState(null);
    const [productCustomizeHeaderId, setProductCustomizeHeaderId] = useState(0);
    const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
    const [uploadConfig, setUploadConfig] = useState({});
    const [detailId, setDetailId] = useState(0);
    const [groupError, setGroupError] = useState(null);
    const [detailError, setDetailError] = useState(null);
    const [showAddGroupButton, setShowAddGroupButton] = useState(true);
    const [fromScreen, setFromScreen] = useState(null);
    const [showEditGroup, setShowEditGroup] = useState(false);
    const [groupHeaderId, setGroupHeaderId] = useState(0);
    const [openPreviewGroup, setOpenPreviewGroup] = useState(false);
    const [groupTemplateId, setGroupTemplateId] = useState(0);
    const [options, setOptions] = useState([]);
    const [openPreviewDetail, setOpenPreviewDetail] = useState(false);
    const [detailHeaderId, setDetailHeaderId] = useState(0);
    const [allGroupTemplateList, setAllGroupTemplateList] = useState([]);

    const handleInputChange = (item) => {
        handleInputItemChange({ [item.name]: item.value });
    };

    const handleInputItemChange = (data) => {
        setInputItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleLookUp = (data) => {
        setLookUp(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleRadioChange = (event) => {
        let selectedVal = parseInt(event.target.value);
        handleInputItemChange({ [event.target.name]: selectedVal });

        if (selectedVal == 1) {
            handleInputItemChange({ "maxCount": 0 });
            handleInputItemChange({ "minCount": 0 });
        }
        filterGroupTemplates(selectedVal);
    };

    const filterGroupTemplates = (val: any) => {
        let templatesList = [];
        templatesList.push(allGroupTemplateList[0]);
        if (val == 1) {
            let itemValues = _.filter(allGroupTemplateList, function (item) {
                return item.code.indexOf("Single") > -1;
            });
            for (let item of itemValues) {
                templatesList.push(item);
            }
        }
        else if (val == 2) {
            let itemValues = _.filter(allGroupTemplateList, function (item) {
                return item.code.indexOf("Multi") > -1;
            });
            for (let item of itemValues) {
                templatesList.push(item);
            }
        }
        handleLookUp({ "GroupTemplate": templatesList });
    }

    const handleCheckbox = (event) => {
        handleInputItemChange({ [event.target.name]: event.target.checked });
    }
    const getCustomizedList = () => {
        let inputData = { productId: inputItem.productId }

        let apiOptions: any = {
            url: 'productcustomize/query',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data
            for (let item of result) {
                for (let itemVal of item.details) {
                    itemVal.imageUrl = general.getImageUrl(itemVal.imagePath);
                    itemVal.displaySection = "detaillist";
                }
            }
            setCustomizedList(result);
            //setShowDetailList(true);
        })
    }

    const initLookup = () => {
        let inputData = {
            lookups: {
                GroupTemplate: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            handleLookUp({ "GroupTemplate": response.data.GroupTemplate });
            setAllGroupTemplateList(JSON.parse(JSON.stringify(response.data.GroupTemplate)));
        });
    }

    const openCustomizeForm = (id: any = 0) => {
        setCustomizeFormOpen(true);
        setShowAddGroupButton(false);
        setGroupError(null);
        handleInputItemChange({ "groupTypeId": "" });
        handleInputItemChange({ "groupPrice": 0 });
        handleInputItemChange({ "groupName": "" });
        handleInputItemChange({ "maxCount": 0 });
        handleInputItemChange({ "minCount": 0 });
    }

    const cancelCustomizeHeader = () => {
        setGroupError(null);
        setCustomizeFormOpen(false);
        setShowAddGroupButton(true);
        setOpenPreviewGroup(false);
    }

    const handleClose = () => {
        props.handleProductCustomForm();
    }

    const saveCustomizeHeader = () => {
        if (!inputItem.groupName || !inputItem.groupTypeId || inputItem.groupTemplateId == -1) {
            setGroupError("This field is required");
            return;
        }
        let inputData = {
            productId: inputItem.productId,
            groupName: inputItem.groupName,
            groupTypeId: inputItem.groupTypeId,
            maxCount: inputItem.maxCount ? inputItem.maxCount : 0,
            minCount: inputItem.minCount ? inputItem.minCount : 0,
            price: inputItem.groupPrice,
            groupTemplateId: inputItem.groupTemplateId
        }

        let apiOptions: any = {
            url: 'productcustomize/save',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            setCustomizeFormOpen(false);
            setShowAddGroupButton(true);
            AlertService.showSuccessMsg();
            getCustomizedList();
        })
    }
    const handleSelectedItem = (item) => {
        setSelectedItem(item);
    };

    const confirmDelete = (item) => {
        setFromScreen("detail");
        handleSelectedItem(item);
        setMessage("Are you sure to delete " + item.description + "?");
        setDeleteOpen(true);
    };

    const closeCancelDialog = () => {
        setDeleteOpen(false);
        setFromScreen(null);
    }

    const deleteCustomizeItem = () => {
        let inputData: any = {
            id: selectedItem.id
        };

        let apiOptions: any = {
            url: `productcustomizedetail/${inputData.id}`
        };
        http.delete(apiOptions).then(res => {
            deleteCustomizeItemCallBack(res);
        });
    };

    const deleteCustomizeItemCallBack = (res) => {
        AlertService.showSuccessMsg();
        setDeleteOpen(false);
        getCustomizedList();
    };


    const openDetailForm = (id: any) => {
        setProductCustomizeHeaderId(id);
        setAddShowDetailForm(true);
        handleInputItemChange({ "id": 0 });
        setInputItem(defaultItem);
        setDetailError(null);
        setGroupHeaderId(0);
    }

    const editDetailForm = (item: any) => {
        setDetailId(item.id);
        setProductCustomizeHeaderId(0);
        getCustomizedItemById(item.id);
        setGroupHeaderId(0);
    }

    const cancelEdit = () => {
        setDetailId(0);
    }

    const getCustomizedItemById = (id: any) => {
        let inputData: any = {
            id: id
        };
        let apiOptions: any = {
            url: `productcustomizedetail/${inputData.id}`
        };
        http.get(apiOptions).then(res => {
            let result = res.data;
            result.imageUrl = general.getImageUrl(result.imagePath);
            setInputItem(result);
            filterGroupTemplates(result.groupTypeId);
        });
    };


    const saveDetail = () => {
        if (inputItem.id == 0 && (!inputItem.description)) {
            setDetailError("This field is required");
            return;
        }
        let inputData = {
            id: detailId,
            productId: inputItem.productId,
            productCustomizeHeaderId: productCustomizeHeaderId,
            description: inputItem.description,
            price: inputItem.price,
            isDefault: inputItem.isDefault,
            imagePath: inputItem.imagePath
        }

        let apiOptions: any = {
            url: 'productcustomizedetail/save',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            if (response.data == detailId) {
                setDetailId(0);
            }
            setInputItem(defaultItem);
            AlertService.showSuccessMsg();
            getCustomizedList();
            setProductCustomizeHeaderId(0);
            setGroupHeaderId(0);
        })
    }

    const cancelDetail = () => {
        setAddShowDetailForm(false);
    }

    const openPhotoCapture = (item: any) => {
        let photoCaptureConfig = {
            id: item.id,
            productId: item.productId,
            //cameraConfig: { url: 'productcustomizedetail/savecameraimage' },
            fileUploadConfig: { url: 'productcustomizedetail/savefileimage' }
        };
        setUploadConfig(photoCaptureConfig);
        setPhotoCaptureOpen(true);
    }

    const handlePhotoCaptureClose = () => {
        setPhotoCaptureOpen(false);
    }

    const onUploadCallBack = (res: any) => {
        setPhotoCaptureOpen(false);
        if (inputItem.id > 0) {
            getCustomizedItemById(inputItem.id);
        }
        else {
            let filePath = res.data.filePath;
            let imagePath = general.getImageUrl(filePath);
            handleInputItemChange({ "imagePath": filePath });
            handleInputItemChange({ "imageUrl": imagePath });
        }
    }

    const editGroupHeader = (id: any) => {
        setShowEditGroup(true);
        setProductCustomizeHeaderId(id);
        getCustomizedGroupById(id);
        setGroupHeaderId(id);
    }

    const cancelEditGroupHeader = () => {
        setShowEditGroup(false);
        setProductCustomizeHeaderId(0);
        setGroupHeaderId(0);
    }

    const getCustomizedGroupById = (id: any) => {
        let inputData: any = {
            id: id
        };
        let apiOptions: any = {
            url: `productcustomize/${inputData.id}`
        };
        http.get(apiOptions).then(res => {
            setInputItem(res.data);
        });
    };

    const onConfirmCallback = () => {
        if (fromScreen == "group") {
            deleteCustomizeGroup();
        }
        else if (fromScreen == "detail") {
            deleteCustomizeItem();
        }
    }

    const confirmDeleteGroup = (item) => {
        setFromScreen("group");
        handleSelectedItem(item);
        setMessage("Are you sure to delete " + item.groupName + "?");
        setDeleteOpen(true);
    };

    const deleteCustomizeGroup = () => {
        let inputData: any = {
            id: selectedItem.id
        };

        let apiOptions: any = {
            url: `productcustomize/${inputData.id}`
        };
        http.delete(apiOptions).then(res => {
            deleteCustomizeGroupCallBack(res);
        });
    };

    const deleteCustomizeGroupCallBack = (res) => {
        AlertService.showSuccessMsg();
        setDeleteOpen(false);
        getCustomizedList();
    };

    const updateCustomizeHeader = () => {
        if (!inputItem.groupName || !inputItem.groupTypeId) {
            setGroupError("This field is required");
            return;
        }
        let inputData = {
            productId: inputItem.productId,
            groupName: inputItem.groupName,
            groupTypeId: inputItem.groupTypeId,
            maxCount: inputItem.maxCount ? inputItem.maxCount : 0,
            minCount: inputItem.minCount ? inputItem.minCount : 0,
            price: inputItem.groupPrice,
            id: productCustomizeHeaderId,
            groupTemplateId: inputItem.groupTemplateId
        }

        let apiOptions: any = {
            url: 'productcustomize/update',
            data: inputData
        };
        http.put(apiOptions).then(response => {
            AlertService.showSuccessMsg();
            setShowEditGroup(false);
            setProductCustomizeHeaderId(0);
            setGroupHeaderId(0);
            getCustomizedList();
        })
    }

    const showPreviewGroup = () => {
        if (inputItem.groupTemplateId > 0) {
            setOptions([]);
            setGroupTemplateId(inputItem.groupTemplateId);
            setOpenPreviewGroup(!openPreviewGroup);
            setOpenPreviewDetail(false);
        }
    }

    const showPreviewDetail = (item: any) => {
        setOpenPreviewGroup(false);
        setDetailHeaderId(item.header.id);
        setGroupTemplateId(item.header.groupTemplateId);
        setOptions(item.details);
        if (detailHeaderId > 0 && detailHeaderId == item.header.id) {
            setOpenPreviewDetail(!openPreviewDetail);
            for (let itemVal of item.details) {
                if (openPreviewDetail) {
                    itemVal.displaySection = "detaillist";
                }
                else {
                    itemVal.displaySection = "";
                }
            }
        }
        else if (detailHeaderId > 0 && detailHeaderId != item.header.id) {
            setOpenPreviewDetail(true);
            for (let itemVal of item.details) {
                itemVal.displaySection = "";
            }
            for (let itemObj of customizedList) {
                for (let item of itemObj.details) {
                    if (itemObj.header.id == detailHeaderId) {
                        item.displaySection = "detaillist";
                    }
                }
            }
        }
        else {
            setOpenPreviewDetail(true);
            for (let itemVal of item.details) {
                itemVal.displaySection = "";
            }
        }
        // for (let itemVal of item.details) {
        //     itemVal.displaySection = !openPreviewDetail ? "" : "detaillist";
        // }

    }

    useEffect(() => {
        initLookup();
        getCustomizedList();
    }, []);

    var cardStyle = {
        // display: 'block',
        width: '450px',

    }

    return (
        <div className="confirmcontainer productcustom-ui mb-5">
            <div className="scroll-ui boxwidth">
            <div className="row title-font mx-auto custom-head">
                <div className="col-11 pl-3 py-1">
                    <span>Item Customization</span>
                </div>
                <div className="col-1 float-right pointer pt-1" onClick={handleClose}>
                    < HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>
            {
                showAddGroupButton &&
                <div className="mx-5 my-2 pt-4">
                    <Button
                        fullWidth
                        onClick={openCustomizeForm}
                        variant="contained"
                    >
                        <b>Add Group</b>
                    </Button>
                </div>
            }


            { customizeFormOpen &&
                <div className="row m-2">
                    <div className="col-6 pt-3">
                        <FxInput name="groupName" variant="outlined" label="Group Name" size="small"
                            value={inputItem.groupName}
                            onValueChange={handleInputChange}
                        />
                        {
                            groupError && !inputItem.groupName &&
                            <div className="col-12 error">{groupError}</div>
                        }
                    </div>
                    <div className="col-6 pt-2">
                        <FormLabel component="legend" className="pb-0">Choice</FormLabel>
                        <RadioGroup row>
                            {
                                groupType.length > 0 && groupType.map((row, i) => (
                                    <div key={i}>
                                        <FormControlLabel
                                            name="groupTypeId"
                                            id={inputItem.groupTypeId}
                                            value={row.id}
                                            control={<Radio color="primary" size="small" />}
                                            label={row.name}
                                            checked={row.id === inputItem.groupTypeId}
                                            onChange={handleRadioChange}
                                        />
                                    </div>
                                ))
                            }
                        </RadioGroup>
                        {
                            groupError && !inputItem.groupTypeId &&
                            <div className="col-12 error">{groupError}</div>
                        }

                    </div>
                    <div className="col-4 pt-3">
                        {inputItem.groupTypeId == 1 &&
                            <FxInput name="minCount" variant="outlined" label="Min Count" size="small"
                                value={inputItem.minCount} onValueChange={handleInputChange} type="number" disabled
                            />
                        }
                        {inputItem.groupTypeId != 1 &&
                            <FxInput name="minCount" variant="outlined" label="Min Count" size="small"
                                value={inputItem.minCount} onValueChange={handleInputChange} type="number"
                            />
                        }

                    </div>
                    <div className="col-4 pt-3">
                        {inputItem.groupTypeId == 1 &&
                            <FxInput name="maxCount" variant="outlined" label="Max Count" size="small"
                                value={inputItem.maxCount} onValueChange={handleInputChange} type="number" disabled
                            />
                        }
                        {inputItem.groupTypeId != 1 &&
                            <FxInput name="maxCount" variant="outlined" label="Max Count" size="small"
                                value={inputItem.maxCount} onValueChange={handleInputChange} type="number"
                            />
                        }

                    </div>
                    <div className="col-4 pt-3">
                        <FxInput name="groupPrice" variant="outlined" label="Price" size="small"
                            value={inputItem.groupPrice} onValueChange={handleInputChange} type="number"
                        />
                    </div>

                    <div className="col-5 pt-3">
                        <FxSelect name="groupTemplateId" variant="outlined" label="Group Template" fullWidth
                            options={lookUp.GroupTemplate}
                            selectedValue={inputItem.groupTemplateId}
                            valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                        {
                            groupError && inputItem.groupTemplateId == -1 &&
                            <div className="col-12 error">{groupError}</div>
                        }
                    </div>
                    <div className="col-1 pt-3 mt-1 pl-0">
                        <span className="pointer" onClick={showPreviewGroup}>
                            <InfoIcon fontSize="small" />
                        </span>
                    </div>

                    <div className="col-6 pr-0 pt-3 row justify-content-end">
                        <span className="pointer">
                            <Button variant="contained" size="small" color="primary"
                                onClick={saveCustomizeHeader}>Save</Button>
                        </span>
                        <span className="pointer pl-3">
                            <Button variant="contained" size="small"
                                onClick={cancelCustomizeHeader}>Cancel</Button>
                        </span>
                    </div>
                </div>
            }

            { openPreviewGroup &&
                <div className="row">
                    <PreviewGroup groupTemplateId={groupTemplateId} options={options} />
                </div>
            }


            <div className="row col-12 pl-5">
                {customizedList && customizedList.length > 0 &&
                    customizedList.map((item) => (
                        <div className="row pt-3">
                            <Card style={cardStyle}>
                                <div className="ctitle-font py-1">
                                    {item.header.id != groupHeaderId &&
                                        <span>
                                            <span className="pl-3"> {item.header.groupName}</span>
                                            <span className="float-right pointer pr-2" onClick={() => openDetailForm(item.header.id)}><AddCircleIcon fontSize="small" /></span>
                                            <span className="float-right pointer pr-2">
                                                <FxPopper iconKey="fas fa-ellipsis-v" popupId={`itemgroup-${nanoid}`}>
                                                    <div className="popper-menu-item" onClick={() => editGroupHeader(item.header.id)}>Edit</div>
                                                    <div className="popper-menu-item" onClick={() => confirmDeleteGroup(item.header)}>Delete</div>
                                                </FxPopper>
                                            </span>
                                            <span className="float-right pointer pr-2" onClick={() => showPreviewDetail(item)}>
                                                <InfoIcon fontSize="small" /></span>
                                        </span>
                                    }
                                    {showEditGroup && item.header.id == groupHeaderId &&
                                        <div className="row">
                                            <div className="col-6 pt-3">
                                                <FxInput name="groupName" variant="outlined" label="Group Name" size="small"
                                                    value={inputItem.groupName}
                                                    onValueChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-6 pt-2">
                                                <FormLabel component="legend" className="pb-0">Choice</FormLabel>
                                                <RadioGroup row>
                                                    {
                                                        groupType.length > 0 && groupType.map((row, i) => (
                                                            <div key={i}>
                                                                <FormControlLabel
                                                                    name="groupTypeId"
                                                                    id={inputItem.groupTypeId}
                                                                    value={row.id}
                                                                    control={<Radio color="primary" size="small" />}
                                                                    label={row.name}
                                                                    checked={row.id === inputItem.groupTypeId}
                                                                    onChange={handleRadioChange}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                </RadioGroup>
                                            </div>
                                            <div className="col-4 mt-3">
                                                {inputItem.groupTypeId == 1 &&
                                                    <FxInput name="minCount" variant="outlined" label="Min Count" size="small" disabled
                                                        value={inputItem.minCount} onValueChange={handleInputChange} type="number"
                                                    />
                                                }
                                                {inputItem.groupTypeId != 1 &&
                                                    <FxInput name="minCount" variant="outlined" label="Min Count" size="small"
                                                        value={inputItem.minCount} onValueChange={handleInputChange} type="number"
                                                    />
                                                }

                                            </div>
                                            <div className="col-4 mt-3">
                                                {inputItem.groupTypeId == 1 &&
                                                    <FxInput name="maxCount" variant="outlined" label="Max Count" size="small" disabled
                                                        value={inputItem.maxCount} onValueChange={handleInputChange} type="number"
                                                    />
                                                }
                                                {inputItem.groupTypeId != 1 &&
                                                    <FxInput name="maxCount" variant="outlined" label="Max Count" size="small"
                                                        value={inputItem.maxCount} onValueChange={handleInputChange} type="number"
                                                    />
                                                }

                                            </div>
                                            <div className="col-4 mt-3">
                                                <FxInput name="groupPrice" variant="outlined" label="Price" size="small"
                                                    value={inputItem.groupPrice} onValueChange={handleInputChange} type="number"
                                                />
                                            </div>

                                            <div className="col-5 mt-3">
                                                <FxSelect name="groupTemplateId" variant="outlined" label="Group Template" fullWidth
                                                    options={lookUp.GroupTemplate}
                                                    selectedValue={inputItem.groupTemplateId}
                                                    valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                                            </div>

                                            <div className="col-6 m-2 pr-4 py-2 row justify-content-end">
                                                <span className="pointer">
                                                    <Button variant="contained" size="small" color="primary"
                                                        onClick={updateCustomizeHeader}>Save</Button>
                                                </span>
                                                <span className="pointer ml-1">
                                                    <Button variant="contained" size="small"
                                                        onClick={cancelEditGroupHeader}>Cancel</Button>
                                                </span>
                                            </div>
                                        </div>
                                    }

                                </div>
                                {openPreviewDetail && item.header.id == detailHeaderId &&
                                    <div className="row">
                                        <PreviewGroup groupTemplateId={groupTemplateId} options={options} />
                                    </div>
                                }
                                {item.details && item.details.length > 0 && item.details.map((item) => (
                                    <div>
                                        { item.displaySection == "detaillist" && item.id != detailId &&
                                            <div className="row py-1">
                                                <div className="col-2">
                                                    <div className="pt-2 pl-2">
                                                        {!item.imageUrl && <img src={dummyImage} alt="dummy" className="image"></img>}
                                                        {item.imageUrl && <img src={item.imageUrl} alt="prod" className="image"></img>}

                                                    </div>
                                                </div>
                                                <div className="col-3 pt-3 text-ui">
                                                    {item.description}
                                                </div>

                                                <div className="col-2 pt-3 text-ui">
                                                    {item.price > 0 &&
                                                        <span> <FxCurrency value={item.price} /></span>
                                                    }

                                                </div>
                                                <div className="col-2 pt-3 text-ui">
                                                    <span>
                                                        {item.isDefault &&
                                                            <span>Active</span>
                                                        }
                                                        {!item.isDefault &&
                                                            <span>InActive</span>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="col-3 pt-3">
                                                    <span className="pointer" onClick={() => editDetailForm(item)}>
                                                        <EditIcon style={{ fontSize: 18 }} />
                                                    </span>
                                                    <span className="pointer pl-3" onClick={() => confirmDelete(item)}>
                                                        <DeleteIcon style={{ fontSize: 18 }} />
                                                    </span>
                                                </div>

                                            </div>

                                        }

                                        { item.id == detailId &&
                                            <div className="row py-3 px-3">
                                                <div className="col-4">
                                                    <div>
                                                        <Link className="pointer">
                                                            {!inputItem.imageUrl && <img src={dummyImage} alt="dummy" className="image"></img>}
                                                            {inputItem.imageUrl && <img src={inputItem.imageUrl} alt="prod" className="image"></img>}
                                                        </Link>
                                                        <div className="text-center camera-align pl-3" onClick={() => openPhotoCapture(item)}>
                                                            <CameraAltIcon color="primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-8">
                                                    <FxInput name="description" variant="outlined" label="Description" size="small"
                                                        fullWidth
                                                        value={inputItem.description}
                                                        onValueChange={handleInputChange}
                                                    />
                                                </div>

                                                <div className="col-4 pt-3">
                                                    <FxInput name="price" variant="outlined" label="Price" size="small"
                                                        value={inputItem.price} onValueChange={handleInputChange} type="number"
                                                    />
                                                </div>
                                                <div className="col-8 pt-3 row justify-content-around">
                                                    <span>
                                                        <Checkbox name="isDefault" color="primary" checked={inputItem.isDefault}
                                                            value={inputItem.isDefault} onChange={handleCheckbox} />Active
                                                        </span>
                                                    <span className="pt-2 pl-4">
                                                        <Button variant="contained" size="small"
                                                            onClick={cancelEdit}>Cancel</Button>
                                                    </span>
                                                    <span className="pt-2 pl-2 pr-0">
                                                        <Button variant="contained" size="small" color="primary"
                                                            onClick={saveDetail}>Save</Button>
                                                    </span>
                                                </div>

                                            </div>
                                        }

                                    </div>

                                ))

                                }

                                {showAddDetailForm && productCustomizeHeaderId == item.header.id &&
                                    <div className="row py-4 px-3">
                                        <div className="col-4">
                                            <div>
                                                <Link className="pointer">
                                                    {!inputItem.imageUrl && <img src={dummyImage} alt="dummy" className="image"></img>}
                                                    {inputItem.imageUrl && <img src={inputItem.imageUrl} alt="prod" className="image"></img>}
                                                </Link>
                                                <div className="text-center camera-align pl-3" onClick={() => openPhotoCapture(item)}>
                                                    <CameraAltIcon color="primary" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8 jus">
                                            <FxInput name="description" variant="outlined" label="Description" size="small"
                                                fullWidth
                                                value={inputItem.description}
                                                onValueChange={handleInputChange}
                                            />
                                            {
                                                detailError && !inputItem.description &&
                                                <div className="col-12 error">{detailError}</div>
                                            }
                                        </div>

                                        <div className="col-4 pt-3">
                                            <FxInput name="price" variant="outlined" label="Price" size="small"
                                                value={inputItem.price} onValueChange={handleInputChange} type="number"
                                            />
                                        </div>
                                        <div className="col-8 pt-3 row justify-content-around">
                                            <span>
                                                <Checkbox name="isDefault" color="primary" checked={inputItem.isDefault}
                                                    value={inputItem.isDefault} onChange={handleCheckbox} />Active
                                        </span>
                                            <span className="pt-2 pl-4">
                                                <Button variant="contained" size="small"
                                                    onClick={cancelDetail}>Cancel</Button>
                                            </span>
                                            <span className="pt-2 pl-2 pr-0">
                                                <Button variant="contained" size="small" color="primary"
                                                    onClick={saveDetail}>Save</Button>
                                            </span>
                                        </div>
                                    </div>
                                }
                            </Card>
                        </div>

                    ))
                }
            </div>
            </div>
            <div>
                <Dialog
                    open={deleteOpen}
                    onClose={closeCancelDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ConfirmDialog fromScreen={fromScreen} message={message} onConfirmCallback={onConfirmCallback} handleClose={closeCancelDialog} />
                </Dialog>
                <Dialog
                    open={photoCaptureOpen}
                    onClose={handlePhotoCaptureClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
                </Dialog>
            </div>
        </div>
    )
};

export default ProductCustomize;