import React, { useEffect, useState } from 'react';
import './DeliveryOptions.scss';
import { AlertService, FxInput, http, general } from "../../fx-core";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, Link } from '@material-ui/core';

const DeliveryOptions = (props: any) => {

  let defaultItem = {
    dineIn: false,
    takeAway: false
  };

  const [item, setItem] = useState(defaultItem);
  const [deliveryOptRequired, setDeliveryOptRequired] = useState('');

  const handleCheckbox = (event) => {
    handleItemChange({ [event.target.name]: event.target.checked });
  }

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  /*const getStoreDeliveryOptions = () => {
    let apiOptions: any = {
      url: `preference/deliveryoptions/${props.storeId}`
    };
    http.get(apiOptions).then(response => {

      let deliveryOptions: any = {};
      for (let item of response.data.deliveryOptions) {
        deliveryOptions[item.preferenceKey] = item.preferenceValue == 1 ? true : false;
      }
      handleItemChange(deliveryOptions);
    })
  }*/

  const getStoreDeliveryOptions = () => {
    let apiOptions: any = {
      url: `stores/${props.storeId}/deliveryoptions`
    };
    http.get(apiOptions).then(res => {
      getStoreDeliveryOptionsCallBack(res);
    })
  }

  const getStoreDeliveryOptionsCallBack = (res) => {
    let deliveryOptions: any = {};
    if(res.data.deliveryOptions.length > 0) {
      for (let item of res.data.deliveryOptions) {
        deliveryOptions["dineIn"] = item.dineIn;
        deliveryOptions["takeAway"] = item.takeAway;
      }
      handleItemChange(deliveryOptions);
    }
  };

  const saveDeliveryOptions = () => {
    setDeliveryOptRequired('');
    if (!item.dineIn && !item.takeAway) {
      setDeliveryOptRequired("Please select any of the delivery options");
      return;
    }
    //let inputData = { storeId: props.storeId, ...item };
    let inputData: any = {
      filters: {
        storeId : general.getOidObjFromVal(props.storeId)
      },
      updateFields: item
    };

    let apiOptions = { 
      url: `stores/${props.storeId}/deliveryoptions`, 
      data: inputData 
    };
    http.put(apiOptions).then(response => {
      saveDeliveryOptionCallback();
    })
  }

  const saveDeliveryOptionCallback = () => {
    AlertService.showSuccessMsg("Delivery options updated successfully");
    getStoreDeliveryOptions();
  }

  useEffect(() => {
    getStoreDeliveryOptions();
  }, []);


  const body = (
    <div className="content-container1 card pb-3">
      <div className="title-font pl-4 py-1">
        <span>Delivery Options</span>
      </div>

      <div className="px-3 pt-2">
      <div>
        <Checkbox name="dineIn" color="primary" checked={item.dineIn}
          value={item.dineIn} onChange={handleCheckbox} />Dine In
    </div>

      <div>
        <Checkbox name="takeAway" color="primary" checked={item.takeAway}
          value={item.takeAway} onChange={handleCheckbox} />Take Away
    </div>

      {deliveryOptRequired &&
        <div className="error"> {deliveryOptRequired} </div>}

      <div className="row justify-content-end">
        <span className="col-3 mr-5 pl-4 pt-2">
          <Button variant="contained" size="small" color="primary" onClick={saveDeliveryOptions}>Save</Button>
        </span>
      </div>
      </div>

    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default DeliveryOptions;