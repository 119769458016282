import React, { useState, useEffect } from 'react';
import './CategoryForm.scss';
import { FxInput, AlertService, http, FxSelect, FxDate, general, formatService, FxMultiSelect } from '../../fx-core';
import { Button, Link, Dialog } from '@material-ui/core';
import { FormValidator } from '../../fx-core/helpers/formValidator';
import dummyImage from "./../../../assets/images/dummy.png";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import Checkbox from "@material-ui/core/Checkbox";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const CategoryForm = (props) => {
    
    const idKey = general.getIdKey();
    const defaultItem: any = {
        categoryCode: "",
        categoryName: "",
        //parentCategoryId: props && props.categoryId > 0 ? props.categoryId : -1,
        parentCategoryId: -1,
        isActive: true,
        id: props && props.categoryId.length > 0 ? props.categoryId : "",
        storeId: props.storeId,
        addAsTile: false,
        /*startDate: formatService.getDateStringForServer(formatService.getCurrentDate()),
        endDate: "",
        timingIds: "",
        areaTypeId: -1*/
    };
    let defaultLookUp: any = {
        ParentCategories: useState([]),
        Timings: useState([]),
        AreaType: useState([])
    }
    const [lookUp, setLookUp] = useState(defaultLookUp);
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});
    const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
    const [uploadConfig, setUploadConfig] = useState({});
    const [error, setError] = useState(null);

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        item.validations = getInputValidations(item.name);
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleCheckbox = (event) => {
        handleItemChange({ [event.target.name]: event.target.checked });
        if (event.target.name == "addAsTile") {
            handleItemChange({ "startDate": formatService.getDateStringForServer(formatService.getCurrentDate()) });
            handleItemChange({ "endDate": "" });
            handleItemChange({ "timingIds": "" });
            handleItemChange({ "areaTypeId": "-1" });
        }
    }

    const handleLookUp = (data) => {
        setLookUp(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookup = () => {
        let storeId = props.storeId;
        let inputData = {
            lookups: {
                ParentCategories: { default: true },
                Timings: { default: false },
                AreaType: { default: true }
            },
            filters: { storeId }
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            handleLookUp({ "ParentCategories": response.data.ParentCategories });
            handleLookUp({ "AreaType": response.data.AreaType });
            getTimingList();
        });
    }

    const getTimingList = () => {
        let inputData = { storeId: props.storeId }

        let apiOptions: any = {
            url: 'timing/query',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            for (let item of result) {
                let startTime = formatService.getTimeDisplay(item.startTime);
                let endTime = formatService.getTimeDisplay(item.endTime);
                item.displayText = item.name + " (" + startTime + " - " + endTime + ")";
            }
            handleLookUp({ "Timings": result });
        })
    }



    //Validation area starts
    const validationMap_Item = {
        categoryCode: ["required"],
        categoryName: ["required"]
    };

    const getValidations = () => {
        let validationMap;
        validationMap = validationMap_Item;
        return validationMap;
    }

    const getInputValidations = (name) => {
        let validationMap = getValidations();
        return validationMap[name];
    }

    const bulkValidate = () => {
        let items: any = [];
        let validationMap = getValidations();
        for (var key in validationMap) {
            let result = { name: key, value: item[key], validations: validationMap[key] }
            items.push(result);
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };
    //Validation area ends

    const saveCategory = () => {
        const isValid = bulkValidate();
        if (isValid) {
            if (item.id.length > 0) {
                updateCategory();
            }
            else {
                addCategory();
            }
        }
    }
    const addCategory = () => {
        let inputData = {
            storeId: general.getOidObjFromVal(item.storeId),
            categoryCode: item.categoryCode,
            categoryName: item.categoryName,
            parentCategoryId: item.parentCategoryId,
            isActive: item.isActive,
            /*areaTypeId: item.addAsTile && item.areaTypeId > 0 ? item.areaTypeId : -1,
            startDate: item.addAsTile ? item.startDate : null,
            endDate: item.addAsTile && item.endDate ? item.endDate : null,
            timingIds: item.addAsTile ? item.timingIds : ""*/
        }
        if (item.addAsTile) {
            if (item.areaTypeId == -1) {
                setError("This field is required");
                return;
            }
            //add category with tile
            let apiOptions: any = {
                url: 'category/tile/add',
                data: inputData
            };
            http.post(apiOptions).then(res => {
                saveCategoryCallBack(res);
            });
        }
        else {
            //add category
            let apiOptions: any = {
                url: `stores/${item.storeId}/categories`,
                data: inputData
            };
            http.post(apiOptions).then(res => {
                saveCategoryCallBack(res);
            });
        }
    };

    const updateCategory = () => {
        let inputData = {
            //id: item.id,
            //storeId: item.storeId,
            categoryCode: item.categoryCode,
            categoryName: item.categoryName,
            parentCategoryId: item.parentCategoryId,
            isActive: item.isActive
        };

        let filters: any = {
            storeId : general.getOidObjFromVal(item.storeId)
        };
        filters[idKey] = general.getOidObjFromVal(item.id);

        let reqData: any = {
            filters,
            updateFields: inputData
        }
        let apiOptions: any = {
            url: `stores/${item.storeId}/categories`,
            data: reqData
        };
        http.put(apiOptions).then(res => {
            saveCategoryCallBack(res);
        });
    };

    const saveCategoryCallBack = (res) => {
        AlertService.showSuccessMsg();
        props.handleCategoryFormClose();
    };

    const getCategoryById = () => {
        let filters: any = {};
        filters[idKey] = general.getOidObjFromVal(item.id);

        let inputData: any = {
            filters,
            sorters: {}
        }
        let apiOptions: any = {
            url: `categories/find`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            let results = res.data.items;
            if(results.length > 0) {
                let result = results[0];
                result.imageUrl = general.getImageUrl(result.imagePath);
                result.id = general.getIdFromObj(result);
                result.storeId = general.getOidValFromKey(result.storeId);
                setItem(result);
            }
        });
    };

    const openPhotoCapture = (item: any) => {
        let photoCaptureConfig = {
            id: item.id,
            fileUploadConfig: { url: 'category/savefileimage' }
        };
        setUploadConfig(photoCaptureConfig);
        setPhotoCaptureOpen(true);
    }

    const handlePhotoCaptureClose = () => {
        setPhotoCaptureOpen(false);
    }

    const onUploadCallBack = (res: any) => {
        setPhotoCaptureOpen(false);
        if (item.id > 0) {
            getCategoryById();
        }
        else {
            let filePath = res.data.filePath;
            let imagePath = general.getImageUrl(filePath);
            handleItemChange({ "imagePath": filePath });
            handleItemChange({ "imageUrl": imagePath });
        }
    }

    const handleClose = () => {
        props.handleCategoryFormClose();
    }

    useEffect(() => {
        //initLookup();
        if (item.id.length > 0) {
            getCategoryById();
        }
    }, []);

    return (
        <div className="category-ui">
            <div className="boxwidth scroll-ui">
                <div className="row title-font mx-auto custom-head">
                    <div className="col-11 pl-3 py-1">
                        <span>Category Form</span>
                    </div>
                    <div className="col-1 float-right pointer pt-1" onClick={handleClose}>
                        < HighlightOffIcon fontSize="small" color="secondary" />
                    </div>
                </div>
                <div className="col-12 mx-auto pt-3 mt-2">
                    <div>
                        <div>
                            <Link className="pointer">
                                {!item.imageUrl && <img src={dummyImage} alt="dummy" className="image-photo"></img>}
                                {item.imageUrl && <img src={item.imageUrl} alt="prod" className="image-photo"></img>}
                            </Link>
                            <div className="text-center camera-align pointer pl-5" onClick={() => openPhotoCapture(item)}>
                                <CameraAltIcon color="primary" />
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">
                        <FxInput name="categoryCode" variant="outlined" label="Category Code" size="small" fullWidth
                            value={item.categoryCode}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("categoryCode", "required") &&
                            <div className="error">Field is required</div>
                        }
                    </div>

                    <div className="pt-4">
                        <FxInput name="categoryName" variant="outlined" label="Category Name" size="small" fullWidth
                            value={item.categoryName}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("categoryName", "required") &&
                            <div className="error">Field is required</div>
                        }
                    </div>

                    <div className="pt-4">
                        <FxSelect name="parentCategoryId" variant="outlined" label="Parent Categories" fullWidth
                            options={lookUp.ParentCategories}
                            selectedValue={item.parentCategoryId}
                            valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                    </div>
                    <div className="pt-1 float-right pb-5">
                        <Checkbox name="isActive" color="primary" checked={item.isActive}
                            value={item.isActive} onChange={handleCheckbox} />Active
                </div>
                    {/*item.id == 0 &&
                        <div className="pt-1 float-right pb-5">
                            <Checkbox name="addAsTile" color="primary" checked={item.addAsTile}
                                value={item.addAsTile} onChange={handleCheckbox} />Add as Tile
                        </div>
                    */}


                    {item.addAsTile &&
                        <span>
                            <div>
                                <FxSelect name="areaTypeId" variant="outlined" label="Area" fullWidth
                                    options={lookUp.AreaType}
                                    selectedValue={item.areaTypeId}
                                    valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                                {error && item.areaTypeId == -1 && <div className="error"> {error} </div>}
                            </div>

                            <div>
                                <FxDate name="startDate" label="Start Date" variant="outlined" className="dateStyle"
                                    value={item.startDate} onValueChange={handleInputChange} />
                            </div>

                            <div className="pt-2">
                                <FxDate name="endDate" label="End Date" variant="outlined" className="dateStyle"
                                    value={item.endDate} onValueChange={handleInputChange} />
                            </div>
                            <div className="pt-3">
                                <FxMultiSelect name="timingIds" variant="outlined" label="Timings" fullWidth
                                    options={lookUp.Timings}
                                    selectedValue={item.timingIds}
                                    valueField="id" displayField="displayText" size="small" onValueChange={handleInputChange} />
                            </div>
                        </span>
                    }

                    <div className="pt-5">
                        <div className="py-1 custom-footer">
                            <span className="float-right pr-4">
                                <Button variant="contained" size="small" color="primary" onClick={saveCategory}>Save</Button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Dialog
                    open={photoCaptureOpen}
                    onClose={handlePhotoCaptureClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
                </Dialog>
            </div>
        </div>
    )
};

export default CategoryForm;