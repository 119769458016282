import moment from 'moment';
import { session }  from './sessionService';

export const general = {
    getFormattedDate: getFormattedDate,
    getFormattedTime: getFormattedTime,
    getImageUrl: getImageUrl,
    getFiltersObjById: getFiltersObjById,
    getIdFromObj: getIdFromObj,
    getIdKey: getIdKey,
    getOidValFromKey: getOidValFromKey,
    getOidObjFromVal: getOidObjFromVal
}

function getFormattedDate(date: any) {
    let formattedDate = moment(date).format("DD/MM/yyyy");
    return formattedDate;
}
function getFormattedTime(time: any) {
    let formattedTime = moment(time, 'hhmm').format('hh:mm A');
    return formattedTime;
}

function getImageUrl(imagePath:any) {
    if (imagePath) {
        let imagStorageUrl = session.getItem("imagStorageUrl");
        let result = imagStorageUrl + imagePath;
        return result;
    }
    return "";
}

function getFiltersObjById(id: any) {
    let result:any = {};
    if(id && id.length > 0) {
        result["_id"] = {"$oid": id };
    }
    return result;
}

function getIdKey() {
    return "_id";
}

function getIdFromObj(item: any) {
    let result = "";
    if(item && item["_id"]) {
        result = item["_id"]["$oid"];
    }
    return result;
}

function getOidValFromKey(item: any) {
    let result = "";
    if(item && item["$oid"]) {
        result = item["$oid"];
    }
    return result;
}

function getOidObjFromVal(item: any) {
    let result:any = {};
    if(item && item.length > 0) {
        result["$oid"] = item;
    }
    return result;    
}