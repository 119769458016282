import React, { useEffect, useState } from 'react';
import './ProductTpl.scss';
import { http, AlertService, FxInput } from '../../fx-core';
import { Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const ProductTpl = (props) => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);

    const handleInputChange = (item) => {
        setEmail(item.value);
    };

    const handleClose = () => {
        props.handleProductTplClose();
    };

    const sendTemplateMail = () => {
        if (email.trim().length <= 0) {
            setError("This field is required");
            return;
        }
        let inputData = { email: email };
        let apiOptions: any = {
            url: `store/sendTemplateEmail`,
            data: inputData
        };
        http.post(apiOptions).then(res => {
            AlertService.showSuccessMsg("Template sent successfully");
            props.handleProductTplClose();
        });
    };

    useEffect(() => {
    }, []);

    const body = (
        <div className="modalalign widthstyle">
            <div className="row title-font py-1 mx-auto">
                <div className="col-10 pl-3 ">
                    <span>Items Template</span>
                </div>
                <div className="col-2 float-right pl-4 pointer" onClick={handleClose}>
                    < HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-2 ml-2">
                    <FxInput name="email" variant="outlined" label="Email" size="small" className="textstyle"
                        value={email} onValueChange={handleInputChange}
                    />
                    {error &&
                        <div className="error"> {error} </div>}
                </div>
            </div>
            <div className="float-right m-2">
                <Button variant="contained" size="small" color="primary" onClick={sendTemplateMail}>
                    Send Email
                    </Button>
            </div>
        </div>
    );

    return (
        <div>
            {body}
        </div>
    );
}
export default ProductTpl;
