import React from 'react';
import NumberFormat from 'react-number-format';
import { session } from '../../fx-core';

export const FxCurrency = (props: any) => {

    let currency = session.getItem("currency");
    const getCurrency = () => {
        let number = props.value;
        const currency = number.toFixed(2);
        return currency;
    }

    return (
        <span>
            {currency == 'AUD' &&
                <span className="pr-1">A$</span>}
            <NumberFormat
                value={getCurrency()}
                displayType={'text'}
                thousandSeparator={true}
                renderText={value => <span>{value}</span>}
            />
        </span>
    );

};