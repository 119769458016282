import React, { useEffect, useState } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel'
import './ImageSlides.scss';
import { http, general, AlertService } from '../../fx-core';

const ImageSlides = (props: any) => {

    const initialList = [];
    const [items, setItems] = useState(initialList);
    const [currentContext, setCurrentContext] = useState(props.imageConfig);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const getImages = () => {
        let apiOptions: any = {
            url: currentContext.api
        };
        http.post(apiOptions).then(response => {
            let result = response.data;
            for (let item of result) {
                item.imageUrl = general.getImageUrl(item.imagePath);
            }
            setItems(result);
        });
    }

    const handleClose = () => {
        props.handleImageSlideClose();
    }

    const deleteImage = () => {
        let item = items[selectedIndex];
        let inputData = {
            id: item.id
        }
        let apiOptions: any = {
            url: currentContext.deleteImageApi, data: inputData
        };
        http.post(apiOptions).then(response => {
            AlertService.showSuccessMsg("Image deleted successfully");
            getImages();
        });
    }

    const updateDefaultImage = () => {
        let item = items[selectedIndex];
        let inputData = {
            id: item.id
        }
        let apiOptions: any = {
            url: currentContext.updateDefaultImageApi, data: inputData
        };
        http.post(apiOptions).then(response => {
            AlertService.showSuccessMsg("Default image updated successfully");
            getImages();
        });
    }

    const handleChange = (selectedIdx) => {
        setSelectedIndex(selectedIdx);
    }

    useEffect(() => {
        getImages();
    }, []);

    const body = (
        <div className="imageslide-ui">
            <div className="row title-font mx-auto">
                <div className="col-11 pl-3 py-1">
                    <span>{currentContext.title}</span>
                </div>
                <div className="col-1 pt-1 float-right pointer" onClick={handleClose}>
                    < HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>

            {items && items.length > 0 &&
                <div className="row m-2 slidewidth">
                    <Carousel onChange={handleChange}>

                        {
                            items && items.map((item) => (
                                <div className="text-center" onChange={handleChange}>
                                    {item.isDefault == true &&
                                        <div>Default Image</div>
                                    }
                                    <div>
                                        <img src={item.imageUrl} alt="image"></img>
                                    </div>

                                </div>
                            ))}
                    </Carousel>
                </div>
            }

            {items && items.length == 0 &&
                <div className="row m-2 slidewidth">No images found</div>
            }


            {items && items.length > 0 &&
                <div className="row m-2">
                    {currentContext.deleteImageApi &&
                        <div className="col-4">
                            <Button variant="contained" size="small" color="primary" onClick={deleteImage}>
                                Delete
                            </Button>
                        </div>
                    }

                    {currentContext.updateDefaultImageApi &&
                        <div className="col-8">
                            <span className="float-right">
                                <Button variant="contained" size="small" color="primary" onClick={updateDefaultImage}>
                                    Set as Default
                                </Button>
                            </span>
                        </div>
                    }

                </div>
            }

        </div>
    );


    return (
        <div>
            {body}
        </div>
    );

}
export default ImageSlides;
