import React, { useEffect, useState } from 'react';
import './StoreProfile.scss';
import { session } from "../../fx-core";
import StoreGeneral from '../StoreGeneral/StoreGeneral';
import DeliveryOptions from '../DeliveryOptions/DeliveryOptions';

const StoreProfile = (props: any) => {

  let storeIdVal = session.getItem('storeId');
  const [storeId, setStoreId] = useState(storeIdVal);

  useEffect(() => {
  }, []);


  const body = (
    <div className="content-container storeprofile-ui">
      <div className="scroll-ui">
      <div className="title-font mt-n3 pl-4 py-2 custom-head">
        <span>Profile</span>
      </div>
      
      <div className="col-12 p-0 pt-3 row justify-content-center">
        <div className="col-5 p-0 pt-5 px-3">
          <StoreGeneral storeId={storeId} />
        </div>
        <div className="col-5 p-0 pt-5 px-3">
          <DeliveryOptions storeId={storeId} />
        </div>
      </div>
      </div>
      
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default StoreProfile;