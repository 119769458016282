import React from 'react';
import './RestaurantFormContainer.scss';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import RestaurantForm from '../RestaurantForm/RestaurantForm';
import DeliveryOptions from '../../admin/DeliveryOptions/DeliveryOptions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const RestaurantFormContainer = (props) => {
    const history = useHistory();
    const storeId = props.location.state.id? props.location.state.id : "";

    const back = () => {
        history.goBack();
    };

    return (
        <div className="restaurantcontainer-ui col-12 pr-0">
            <div className="scroll-ui">
            
            {
                storeId.length <= 0 &&
                <div className="col-5 mx-auto pt-5 mt-5">
                    <RestaurantForm storeId={storeId} back={back} />
                </div>
            }
            {
                storeId.length > 0 &&
                <div className="pl-3 pt-5 mt-4">
                    <div className="titlefont mt-n3 pl-4 py-2 custom-head">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="primary" className="custom-link pointer" onClick={back}>
                            Restaurants
                        </Link>                
                        <span>Restaurant Form</span>
                    </Breadcrumbs>
                    </div>
                    <div className="col-12 p-0 pt-3 row justify-content-center">
                        <div className="col-5 p-0 px-1">
                            <RestaurantForm storeId={storeId} />
                        </div>
                        <div className="col-5 p-0 px-1">
                            <DeliveryOptions storeId={storeId} />
                        </div>
                    </div>
                    {/* <div className="col-12 mt-2 justify-content-start">
                        <span>
                            <Button variant="contained" size="small" color="primary" onClick={back}>Back</Button>
                        </span>
                    </div> */}
                </div>
            }
            </div>
        </div>
    );
};

export default RestaurantFormContainer;