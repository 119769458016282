import React,{ useState } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Link,Dialog, Card } from '@material-ui/core';
import './UploadProducts.scss';
import { AlertService, http, FxUploader } from '../../fx-core';
import ProductTpl from '../../general/ProductTpl/ProductTpl';

const UploadProducts = (props: any) => {

    const [productTplDialogOpen, setProductTplDialogOpen] = useState(false);

    const onUploadProductsExcel = (files) => {
        console.log('onUploadProductsExcel files=', files);
        if (files && files.length > 0) {
            const formData = new FormData()
            formData.append('file', files[0])
            let apiOptions = { url: 'store/UploadProducts', data: formData };
            http.post(apiOptions).then((response: any) => {
                console.log('Upload prodcuts success');
                AlertService.showSuccessMsg("Products upload successfully");
                props.onUploadCallBack();
            }).catch(error => {
                console.log('Upload error=', error);
            });
        }
    }

    const onUploadProductImages = (files) => {
        console.log('onUploadProductImages files=', files);
        if (files && files.length > 0) {
            const formData = new FormData()
            formData.append('file', files[0])
            let apiOptions = { url: 'store/product/UploadImages', data: formData };
            http.post(apiOptions).then((response: any) => {
                console.log('Upload product images success');
                AlertService.showSuccessMsg("Product Images upload successfully");
                props.onUploadCallBack();
            }).catch(error => {
                console.log('Upload error=', error);
            });
        }
    }

    const openProductTplModal = () => {
        setProductTplDialogOpen(true);
    }

    const handleProductTplClose = () => {
        setProductTplDialogOpen(false);
    }

    var cardStyle = {
        // display: 'block',
        backgroundColor: '#ECFBF3',
        width: 420,

    }


    const body = (
        <div className="upload-ui">
            <div className="boxwidth scroll-ui">
            <div className="row col-12 title-font py-1 mx-auto custom-head">
                <div className="col-11 pl-0">
                    <span>Upload Items</span>
                </div>
                <div className="col-1 pr-0 float-right pointer" onClick={props.handleUploadClose}>
                    < HighlightOffIcon fontSize="small" color="secondary" />
                </div>
            </div>

            <div className="row mx-auto pt-5">
            <div className="dragdrop px-2 py-2 mx-auto">
                <span className="uploadhead-ui">Upload Items</span>
            <div className="pt-2">
            <Card style={cardStyle}>
                <div className="numberlist pt-2">
                    <ul>
                        <li>Email
                            <span>
                                <Link className="pointer ml-1" onClick={openProductTplModal}>the sample format excel here</Link>
                            </span>
                        </li>
                        <li className="pt-2"> Fill in item information in appropriate columns</li>
                        <li className="pt-2"> Upload the filled-in file</li>
                        <li className="pt-2"> Hurrah! new items added</li>
                    </ul>
                    <br />
                    <div className="marginText pl-3">
                        <FxUploader onUpload={onUploadProductsExcel} />
                    </div>
                </div>
            </Card>
            </div>
            </div>
            <br /><br />
            <div className="dragdrop px-2 py-2 pt-5 mx-auto">
                <span className="uploadhead-ui">Item Images Zip</span>
            <div className="pt-2">
                <Card style={cardStyle}>
                <div className="numberlist pt-2">
                    <ul>
                        <li> Zip File content: folder with item images</li>
                        <li className="pt-2"> Folder name - ItemCode</li>
                        <li className="pt-2"> For Ex: pizza/image1.jpg, pizza/image2.jpg</li>
                    </ul>
                    <br />
                    <div className="marginText pl-3">
                        <FxUploader onUpload={onUploadProductImages} />
                    </div>
                </div>
                </Card>
            </div>
            </div>
            </div>
            </div>
            <div>
            <Dialog
                open={productTplDialogOpen}
                onClose={handleProductTplClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <ProductTpl handleProductTplClose={handleProductTplClose} />
            </Dialog>
            </div>
        </div>
    );


    return (
        <div>
            {body}
        </div>
    );

}
export default UploadProducts;
