import React, { useState } from 'react';
import './Header.scss';
import logo from "./../../assets/images/logo.gif";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useLocation } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Leftmenu from '../leftmenu/Leftmenu';

const Header = () => {
  let location = useLocation();

  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };


  if (location.pathname == '/' || location.pathname == '/login') {
    return <></>;
  } else {
    return (
      <div className="row header-ui">
        <div className="col-12">
          <AppBar color="secondary">
            <Toolbar>
              <div className="logo-ui">
                {/* <img src={logo} alt="Logo" /> */}
              </div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start">
                <MenuRoundedIcon color="primary" />
              </IconButton>

              <div className="col-5">
                <h2 className="titlestyle">Getme Admin</h2>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer variant="persistent" anchor="left" className="drawer-leftmenu" open={open}>
            <span onClick={toggleDrawer}>
              <Leftmenu />
            </span>
          </Drawer>
        </div>
      </div>
    );
  }
}
export default Header;