import React, { useEffect, useState } from 'react';
import './StoreGeneral.scss';
import { FxInput, http, AlertService, FxSelect, LocationSearchInput, general } from '../../fx-core';
import { Button, Dialog, Link } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import { MobileVerificationDialog } from '../MobileVerificationDialog/MobileVerificationDialog';
import { EmailVerificationDialog } from '../EmailVerificationDialog/EmailVerificationDialog';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import dummyImage from "./../../../assets/images/dummy.png";
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import Badge from '@material-ui/core/Badge';

const StoreGeneral = (props: any) => {
  let defaultForm: any = {
    storeName: "",
    storeCode: "",
    businessTypeId: "",
    email: "",
    mobile: "",
    address: "",
    isMobileVerified: false,
    isEmailVerified: false,
    imageUrl: ""
  };
  let defaultLookUp: any = {
    BusinessType: useState([])
  }
  const [lookUp, setLookUp] = useState(defaultLookUp);

  const [item, setItem] = useState(defaultForm);
  const [mobileVerifyOpen, setMobileVerifyOpen] = useState(false);
  const [emailVerifyOpen, setEmailVerifyOpen] = useState(false);
  const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
  const [uploadConfig, setUploadConfig] = useState({});
  const [imageSlideOpen, setImageSlideOpen] = useState(false);
  const [imageConfig, setImageConfig] = useState({});

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handleLookUp = (data) => {
    setLookUp(prevState => {
      return { ...prevState, ...data }
    });
  };

  const initLookup = () => {
    let inputData = {
      lookups: {
        BusinessType: { default: true }
      },
      filters: {}
    };
    let apiOptions = {
      url: 'options/lookup',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      handleLookUp({ "BusinessType": response.data.BusinessType });
    });
  }

  const getStoreProfile = () => {
    let inputData = {
      id: props.storeId
    };
    let apiOptions: any = {
      url: 'store/profile',
      data: inputData
    };
    http.get(apiOptions).then(response => {
      let result = response.data;
      result.imageUrl = general.getImageUrl(result.profileImagePath);
      setItem(result);
    })
  }

  const saveProfile = () => {
    let inputData = {
      id: props.storeId,
      address: item.address,
      businessTypeId: item.businessTypeId
    };
    let apiOptions: any = {
      url: 'store/profile',
      data: inputData
    };
    http.put(apiOptions).then(response => {
      AlertService.showSuccessMsg();
    })
  }

  const addressSelected = (data) => {
    let inputData = { address: data.address };
    handleItemChange(inputData);
  };

  const openMobileVerify = () => {
    setMobileVerifyOpen(true);
  };

  const closeMobileVerify = () => {
    setMobileVerifyOpen(false);
  };

  const onSuccessMobileVerify = (res) => {
    if (res == "success") {
      handleItemChange({ isMobileVerified: true });
    }
    closeMobileVerify();
  };

  const openEmailVerify = () => {
    setEmailVerifyOpen(true);
  };

  const closeEmailVerify = () => {
    setEmailVerifyOpen(false);
  };

  const onSuccessEmailVerify = (res) => {
    if (res == "success") {
      handleItemChange({ isEmailVerified: true });
    }
    closeEmailVerify();
  };

  const openPhotoCapture = () => {
    let photoCaptureConfig = {
      id: props.storeId,
      // cameraConfig: { url: 'store/savecameraimage' },
      fileUploadConfig: { url: 'store/savefileimage' }
    };
    setUploadConfig(photoCaptureConfig);
    setPhotoCaptureOpen(true);
  }

  const handlePhotoCaptureClose = () => {
    setPhotoCaptureOpen(false);
    getStoreProfile();
  }

  const onUploadCallBack = (res: any) => {
    setPhotoCaptureOpen(false);
    getStoreProfile();
  }

  const openImages = (item: any) => {
    let config = {
      api: `store/${item.id}/images`,
      title: item.storeName,
      updateDefaultImageApi: `store/updateDefaultImage`,
      deleteImageApi: `store/deleteImage`,
    };
    setImageConfig(config);
    setImageSlideOpen(true);
  }

  const handleImageSlideClose = () => {
    setImageSlideOpen(false);
    getStoreProfile();
  }

  useEffect(() => {
    initLookup();
    getStoreProfile();
  }, []);


  const body = (
    <div className="content-container2 card pb-3 storegeneral-ui">
      <div className="title-font pl-4 py-1">
        <span>General</span>
      </div>
      <div className="pl-3 pt-3 px-4">
        <div>
          <div>
            <Link className="pointer" onClick={() => openImages(item)}>
              {!item.imageUrl && <img src={dummyImage} alt="dummy"></img>}
              {item.imageUrl && <img src={item.imageUrl} alt="prod"></img>}
            </Link>
            <div className="text-center camera-align pl-5 ml-5" onClick={openPhotoCapture}>
              <CameraAltIcon color="primary" />
            </div>
          </div>

          <div className="col-12 row pt-4">
            <div className="col-12">
              <PersonIcon fontSize="small" />
              <span className="pl-2">{item.storeName}</span>
              <span className="pl-4">
                {/* <Badge className="badge" color="primary">{item.storeCode}</Badge> */}
                <Badge color="primary" badgeContent={item.storeCode}></Badge>
              </span>
            </div>
          </div>
          <div className="col-12 row pt-4">
            <div className="col-6 pr-0">
              <EmailIcon fontSize="small" />
              <span className="pl-2">{item.email}</span>
            </div>
            <div className="col-6 pr-0">
              <CallIcon fontSize="small" />
              <span className="pl-2">{item.mobile}</span>
              {/* {
                  !item.isMobileVerified &&
                  <Link className="float-right pointer" onClick={openMobileVerify}>Verify</Link>
                }
                {
                  item.isMobileVerified &&
                  <span className="float-right verified">Verified</span>
                } */}
            </div>
          </div>
          <div className="pt-5">
            <FxSelect name="businessTypeId" variant="outlined" label="Business Type" fullWidth
              options={lookUp.BusinessType}
              selectedValue={item.businessTypeId}
              valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
          </div>
          <div className="pt-4">
                {item.address &&  <LocationSearchInput address={item.address} name="address"
                    fullWidth
                    locationSelected={addressSelected} placeholder="Address" /> }
                {!item.address &&  <LocationSearchInput address={item.address} name="address"
                    fullWidth
                    locationSelected={addressSelected} placeholder="Address" /> }
          </div>
        </div>
      </div>


      <div className="row text-right pt-3 px-4">
        <span className="col-12 pt-2">
          <Button variant="contained" size="small" color="primary" onClick={() => saveProfile()}>Update</Button>
        </span>
      </div>
      <Dialog
        open={mobileVerifyOpen}
        onClose={closeMobileVerify}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <MobileVerificationDialog handleVerification={onSuccessMobileVerify} closeDialog={closeMobileVerify} />
      </Dialog>
      <Dialog
        open={emailVerifyOpen}
        onClose={closeEmailVerify}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <EmailVerificationDialog handleVerification={onSuccessEmailVerify} closeDialog={closeEmailVerify} />
      </Dialog>
      <Dialog
        open={photoCaptureOpen}
        onClose={handlePhotoCaptureClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
      </Dialog>
      <Dialog
        open={imageSlideOpen}
        onClose={handleImageSlideClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
      </Dialog>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}

export default StoreGeneral;