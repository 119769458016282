import React, { useState, useEffect } from 'react';
import './RestaurantForm.scss';
import { FxInput, FxTextarea, AlertService, http, FxSelect, LocationSearchInput, general } from '../../fx-core';
import { Button, Link, Dialog, Card } from '@material-ui/core';
import { FormValidator } from '../../fx-core/helpers/formValidator';
import dummyImage from "./../../../assets/images/dummy.png";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoCapture from '../../general/PhotoCapture/PhotoCapture';
import ImageSlides from '../../general/ImageSlides/ImageSlides';
import { useHistory } from "react-router-dom";

const RestaurantForm = (props) => {

    const history = useHistory();
    const storeId = props.storeId;

    const defaultItem: any = {
        userName: "",
        storeName: "",
        storeCode: "",
        email: "",
        mobile: "",
        id: storeId,
        businessTypeId: -1,
        address: "",
        contactName: "",
        currency:"",
        taxTypeId:0,
        taxCodeId:-1
    };
    let defaultLookUp: any = {
        BusinessType: useState([]),
        Currency: useState([]),
        TaxModalType: useState([]),
        TaxCode: useState([])
    };

    const [lookUp, setLookUp] = useState(defaultLookUp);
    const [item, setItem] = useState(defaultItem);
    const [errors, setErrors] = useState({});
    const [photoCaptureOpen, setPhotoCaptureOpen] = useState(false);
    const [imageSlideOpen, setImageSlideOpen] = useState(false);
    const [uploadConfig, setUploadConfig] = useState({});
    const [imageConfig, setImageConfig] = useState({});

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        item.validations = getInputValidations(item.name);
        const validationResult = FormValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemChange = (data) => {
        setItem(prevState => {
            return { ...prevState, ...data }
        });
    };

    const handleLookUp = (data) => {
        setLookUp(prevState => {
            return { ...prevState, ...data }
        });
    };

    const initLookup = () => {
        /*let inputData = {
            lookups: {
                BusinessType: { default: true },
                Currency: { default: false },
                TaxModalType: { default: true },
                TaxCode: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            handleLookUp({ "BusinessType": response.data.BusinessType });
            handleLookUp({ "Currency": response.data.Currency });
            handleLookUp({ "TaxModalType": response.data.TaxModalType });
            handleLookUp({ "TaxCode": response.data.TaxCode });
            handleItemChange({"currency":response.data.Currency[0].code});
            handleItemChange({"taxModalTypeId":"-1"});

            if (storeId.length > 0) {
                getStoreById();
            }
        });*/

        const BusinessType = 
        [
            {
                "id": -1,
                "text": "Please select...",
                "code": "-1",
                "isDefault": false,
                "attributes": null
            },
            {
                "id": 1,
                "text": "Restaurant",
                "code": "Restaurant",
                "isDefault": false,
                "attributes": null
            },
            {
                "id": 2,
                "text": "Bar",
                "code": "Bar",
                "isDefault": false,
                "attributes": null
            }
        ];
        
        const Currency =
        [
            {
                "id": 1,
                "text": "AUD",
                "code": "AUD",
                "isDefault": false,
                "attributes": null
            }
        ];
        
        const TaxCode =
        [
            {
                "id": -1,
                "text": "Please select...",
                "code": "-1",
                "isDefault": false,
                "attributes": null
            },
            {
                "id": 1,
                "text": "GST",
                "code": "GST",
                "isDefault": false,
                "attributes": null
            },
            {
                "id": 2,
                "text": "Tax %",
                "code": "Tax %",
                "isDefault": false,
                "attributes": null
            }
        ];
        
        const TaxModalType =
        [
            {
                "id": -1,
                "text": "Please select...",
                "code": "-1",
                "isDefault": false,
                "attributes": null
            },
            {
                "id": 1,
                "text": "OrderLevel",
                "code": "OrderLevel",
                "isDefault": false,
                "attributes": null
            },
            {
                "id": 2,
                "text": "ItemLevel",
                "code": "ItemLevel",
                "isDefault": false,
                "attributes": null
            }
        ];

        handleLookUp({ "BusinessType": BusinessType });
        handleLookUp({ "Currency": Currency });
        handleLookUp({ "TaxModalType": TaxModalType });
        handleLookUp({ "TaxCode": TaxCode });
        handleItemChange({"currency": Currency[0].code });
        handleItemChange({"taxModalTypeId":"-1"});
        
        if (storeId && storeId.length > 0) {
            getStoreById();
        }
    }


    //Validation area starts
    const validationMap_Item = {
        storeCode:["required"],
        storeName: ["required"],
        userName: ["required"],
        email: ["required", "email"],
        mobile: ["required"],
        address: ["required"],
        businessType: ["combo"],
        contactName: ["required"]
    };

    const getValidations = () => {
        let validationMap;
        validationMap = validationMap_Item;
        return validationMap;
    }

    const getInputValidations = (name) => {
        let validationMap = getValidations();
        return validationMap[name];
    }

    const bulkValidate = () => {
        let items: any = [];
        let validationMap = getValidations();
        for (var key in validationMap) {
            if (item.id.length > 0 && (key == "userName" || key == "contactName")) {
                continue;
            }
            let result = { name: key, value: item[key], validations: validationMap[key] }
            items.push(result);
        }
        let validationResult = FormValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    }

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    }

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };
    //Validation area ends

    const addStore = () => {
        const isValid = bulkValidate();
        if (isValid) {
            item.businessTypeId = 1;

            let apiOptions: any = {
                url: 'stores',
                data: item
            };
            http.post(apiOptions).then(res => {
                saveStoreCallBack(res);
            });
        }
    };

    const updateStore = () => {
        item.userName = item.userName;
        const isValid = bulkValidate();
        if (isValid) {
            let inputData: any = {
                filters: general.getFiltersObjById(item.id),
                updateFields: item
            };
            let apiOptions: any = {
                url: `stores/${item.id}`,
                data: inputData
            };
            http.put(apiOptions).then(res => {
                saveStoreCallBack(res);
            });
        }
    };

    const saveStoreCallBack = (res) => {
        AlertService.showSuccessMsg();
        navigateBack();
    };

    const getStoreById = () => {
        let apiOptions: any = {
            url: `stores/${item.id}`
        };
        http.get(apiOptions).then(res => {
            let result = res.data;
            result.imageUrl = general.getImageUrl(result.profileImagePath);
            result.id = general.getIdFromObj(result);
            setItem(result);
            handleItemChange({ "taxModalTypeId": result.taxModalTypeId > 0 ? result.taxModalTypeId : "-1" });
        });
    };

    const addressSelected = (data) => {
        let inputData = { address: data.address };
        handleItemChange(inputData);
    };

    const openPhotoCapture = (item: any) => {
        let photoCaptureConfig = {
            id: item.id,
            //cameraConfig: { url: 'stores/savecameraimage' },
            fileUploadConfig: { url: 'store/savefileimage' }
        };
        setUploadConfig(photoCaptureConfig);
        setPhotoCaptureOpen(true);
    }

    const handlePhotoCaptureClose = () => {
        setPhotoCaptureOpen(false);
    }

    const onUploadCallBack = (res: any) => {
        setPhotoCaptureOpen(false);
        if (item.id.length > 0) {
            getStoreById();
        }
        else {
            let filePath = res.data.filePath;
            let imagePath = general.getImageUrl(filePath);
            handleItemChange({ "profileImagePath": filePath });
            handleItemChange({ "imageUrl": imagePath });
        }
    }


    const openImages = (item: any) => {
        let config = {
            api: `store/${item.id}/images`,
            title: item.storeName,
            updateDefaultImageApi: `store/updateDefaultImage`,
            deleteImageApi: `store/deleteImage`,
        };
        setImageConfig(config);
        setImageSlideOpen(true);
    }

    const handleImageSlideClose = () => {
        setImageSlideOpen(false);
        if (storeId && storeId.length > 0) {
            getStoreById();
        }
    }

    const navigateBack = () => {
        history.push({
            pathname: '/restaurantlist'
        });
    }

    useEffect(() => {
        initLookup();
    }, []);

    return (
        <div className="restaurantform-ui">
            <Card>
                <div className="title-font py-1 pl-4">
                    <span>Restaurant Form</span>
                </div>
                <div className="col-12 mx-auto pt-3 mt-2 pb-3">
                    <div>
                        <div>
                            <Link className="pointer" onClick={() => openImages(item)}>
                                {!item.imageUrl && <img src={dummyImage} alt="dummy"></img>}
                                {item.imageUrl && <img src={item.imageUrl} alt="store"></img>}
                            </Link>
                            <div className="text-center camera-align pl-5 ml-5 pointer" onClick={() => openPhotoCapture(item)}>
                                <CameraAltIcon color="primary" />
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">
                        {
                            item.id.length == 0 &&
                            <div className="mt-3">
                                <FxInput name="userName" variant="outlined" label="User Name" size="small" fullWidth
                                    value={item.userName}
                                    onValueChange={handleInputChange}
                                />
                                {
                                    hasError("userName", "required") &&
                                    <div className="error">Field is required</div>
                                }
                            </div>
                        }
                        {
                            item.id.length > 0 &&
                            <div className="pt-3">
                                <FxInput name="userName" variant="outlined" size="small" fullWidth
                                    value={item.userName}
                                    disabled
                                />
                            </div>
                        }
                    </div>

                    <div className="pt-4">
                        <FxInput name="storeCode" variant="outlined" label="Restaurant Code" size="small" fullWidth
                            value={item.storeCode}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("storeCode", "required") &&
                            <div className="error">Field is required</div>
                        }
                    </div>

                    <div className="pt-4">
                        <FxInput name="storeName" variant="outlined" label="Restaurant Name" size="small" fullWidth
                            value={item.storeName}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("storeName", "required") &&
                            <div className="error">Field is required</div>
                        }
                    </div>

                    {item.id.length == 0 &&
                        <div className="pt-4">
                            <FxInput name="contactName" variant="outlined" label="Contact Name" size="small" fullWidth
                                value={item.contactName}
                                onValueChange={handleInputChange}
                            />
                            {
                                hasError("contactName", "required") &&
                                <div className="error">Field is required</div>
                            }
                        </div>
                    }

                    <div className="pt-4">
                        <FxSelect name="businessTypeId" variant="outlined" label="Business Type" fullWidth
                            options={lookUp.BusinessType}
                            selectedValue={item.businessTypeId}
                            valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                        {hasError("businessTypeId", "combo") && (
                            <div className="error">Business Type is required</div>
                        )}

                    </div>

                    <div className="pt-4">
                        <FxInput name="mobile" variant="outlined" label="Mobile" size="small" fullWidth
                            value={item.mobile}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("mobile", "required") &&
                            <div className="error">Field is required</div>
                        }
                    </div>




                    <div className="pt-4">
                        <FxInput name="email" variant="outlined" label="Email" size="small" fullWidth
                            value={item.email}
                            onValueChange={handleInputChange}
                        />
                        {
                            hasError("email", "required") &&
                            <div className="error">Field is required</div>
                        }
                        {
                            hasError("email", "email") &&
                            <div className="error">Please enter valid email id</div>
                        }
                    </div>

                    <div className="pt-4">
                        <FxSelect name="currency" variant="outlined" label="Currency" fullWidth
                            options={lookUp.Currency}
                            selectedValue={item.currency}
                            valueField="code" displayField="text" size="small" onValueChange={handleInputChange} />
                    </div>

                    <div className="pt-4">
                        <FxSelect name="taxModalTypeId" variant="outlined" label="Tax Type" fullWidth
                            options={lookUp.TaxModalType}
                            selectedValue={item.taxModalTypeId}
                            valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                    </div>

                    <div className="pt-4">
                        <FxSelect name="taxCodeId" variant="outlined" label="Tax Code" fullWidth
                            options={lookUp.TaxCode}
                            selectedValue={item.taxCodeId}
                            valueField="id" displayField="text" size="small" onValueChange={handleInputChange} />
                    </div>

                    <div className="pt-4">
                        {(item.id.length > 0 && item.address) &&  <LocationSearchInput address={item.address} name="address"
                            fullWidth
                            locationSelected={addressSelected} placeholder="Address" /> }
                        {(item.id.length == 0 || !item.address) &&  <LocationSearchInput address={item.address} name="address"
                            fullWidth
                            locationSelected={addressSelected} placeholder="Address" /> }
                        {hasError("address", "required") && (
                            <div className="error">Address is required</div>
                        )}
                    </div>


                    <div className="row pt-5 pr-4 justify-content-end">

                        <span className="pl-2">
                            {
                                item.id.length == 0 &&
                                <Button variant="contained" size="small" color="primary" onClick={addStore}>Save</Button>
                            }
                            {
                                item.id.length > 0 &&
                                <Button variant="contained" size="small" color="primary" onClick={updateStore}>Save</Button>
                            }
                        </span>
                        <span className="pl-2">
                            {
                                item.id.length == 0 &&
                                <Button variant="contained" size="small" color="primary" onClick={navigateBack}>Back</Button>
                            }
                        </span>
                    </div>
                </div>
            </Card>
            <div>
                <Dialog
                    open={photoCaptureOpen}
                    onClose={handlePhotoCaptureClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <PhotoCapture uploadConfig={uploadConfig} onUploadCallBack={onUploadCallBack} handlePhotoCaptureClose={handlePhotoCaptureClose} />
                </Dialog>
                <Dialog
                    open={imageSlideOpen}
                    onClose={handleImageSlideClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <ImageSlides imageConfig={imageConfig} handleImageSlideClose={handleImageSlideClose} />
                </Dialog>
            </div>
        </div>
    )
};

export default RestaurantForm;