import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Progress from '../progress/Progress';

var node:any;
var progressCount = 0;
const renderProgress = () => {
   if (progressCount == 0)
      return;

   const component = <Progress/>
   if (!node) {
      node = document.createElement('div');
      document.body.appendChild(node);
   }
   ReactDOM.render(component, node);
}

export const ProgressManager = {
   open() {
      progressCount = progressCount + 1;
      if (progressCount == 1) { // render the progress only if there is no other showing progressBars
         renderProgress();
      }
   },
   close() {
      progressCount = progressCount - 1;
      if(progressCount == 0) {
         ReactDOM.unmountComponentAtNode(node);
         renderProgress();
      }
   }
}