import React, { useEffect, useState } from 'react';
import './EmailVerificationDialog.scss';
import { http, AlertService, FxInput } from '../../fx-core';
import { Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export const EmailVerificationDialog = (props) => {

    const [otp, setOtp] = useState("");

    const verifyEmail = () => {
        let apiOptions: any = {
            url: `user/verify/email`,
            data: {}
        };
        http.post(apiOptions);
    };

    const handleInputChange = (item) => {
        setOtp(item.value);
    };

    const handleClose = () => {
        props.closeDialog()
    };

    const verifyEmailStatus = () => {
        if (otp.trim().length <= 0) {
            AlertService.showErrorMsg("Please provide OTP");
            return;
        }        
        let apiOptions: any = {
            url: `user/verify/email/status`,
            data: { otp }
        };
        http.post(apiOptions).then(res => {
            verifyEmailStatusCallBack(res);
        });
    };

    const verifyEmailStatusCallBack = (res) => {
        if(res && res.data) {
            props.handleVerification("success");
        }
        else {
            AlertService.showErrorMsg("Please provide valid OTP");
        }
    };

    useEffect(() => {
        verifyEmail();
    }, []);

    return (
        <div className="modalalign">
            <div className="col-12 title-font">
                <span className="col-10">Email Verification</span>
                <span className="col-1 float-right pointer mr-3" onClick={handleClose}>
                    < HighlightOffIcon fontSize="small" color="secondary" />
                </span>
            </div>
            <div className="col-12 mt-2 ml-2">
                <span>Please enter the OTP received in your email</span>
            </div>
            <div className="col-12 mt-2 ml-2">
                <FxInput name="otp" variant="outlined" label="OTP" size="small"
                    value={otp}
                    onValueChange={handleInputChange}
                />
            </div>
            <div className="float-right mr-2 mb-2">
                <Button variant="contained" size="small" color="primary" onClick={verifyEmailStatus}>Verify</Button>
            </div>
        </div>
    );
};